import * as React from "react";
import { AnimatePresence, motion } from "framer-motion";
import Grid from "@material-ui/core/Grid";
import styled from "styled-components";

const MotionDiv = styled(motion.div)``;

const variants = {
    initial: { opacity: 0, scale: 0 },
    animate: { opacity: 1, scale: 1 },
    exit: { opacity: 0, scale: 0 },
};

export default function FCard(props) {
    return (
        <AnimatePresence>
            {props.values && (
                <Grid item>
                    <MotionDiv
                        initial="initial"
                        animate="animate"
                        exit="exit"
                        variants={variants}
                        whileHover={{ scale: 1.1 }}
                    >
                        {props.children}
                    </MotionDiv>
                </Grid>
            )}
        </AnimatePresence>
    );
}
