import React from "react";
import TextField from "@material-ui/core/TextField";
import FController from "./FControllerHook";

export default function FTextInput({
    value,
    label,
    maxlength,
    rowsMax,
    controller,
    onChange,
    onBlur,
    className,
    placeholder,
    validations,
    type = "text",
    disabled,
    ...rest
}) {
    if (!controller) {
        controller = FController();
    }

    // Inicia o controller com as configurações default
    controller.init(controller, value, validations);

    return (
        <TextField
            {...rest}
            value={controller.value ?? ""}
            error={controller.error}
            className={className}
            fullWidth
            helperText={controller.message}
            variant="outlined"
            label={label}
            disabled={disabled}
            type={type}
            placeholder={placeholder}
            inputProps={{
                maxLength:
                    maxlength !== null && maxlength !== undefined
                        ? parseInt(maxlength)
                        : 50,
            }}
            // onBlur={(e) => {
            //   controller.validate();
            //   if (onBlur) onBlur(e.target.value);
            // }}
            onChange={(e) => {
                controller.setValue(e.target.value);
                if (onChange) onChange(e.target.value);
            }}
        />
    );
}
