import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";

// My Components
import FButton from "../../../../../components/button/FButton";
import FCard from "../../../../../theme/animations/fcard.animations";
import PlayerPopCard from "../../components/cards/player.pop.card";
import CloseButton from '../../../../../components/button/CloseButton'

import PopHeader from '../../components/popHeader';
import PopTeamBody from '../../components/popTeamBody';
import PopBigBox from "../../components/popBigBox";

const useStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(2),
    },
    dialogPaper: {
        position: 'relative',
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        maxWidth: 800,
        minWidth: 800,
        paddingTop: 10,
    },
    body: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        alignItems: 'flex-start'
    },
    teamBox: {
        width: '49%',
        textAlign: 'center'
    },
    golTitle: {
        height: 35,
        width: 85,
        border: `1px solid #cccccc`,
        backgroundColor: '#ffffff',
        borderRadius: 5,
        position: "relative",
        fontSize: 24
    },
    timer: {
        "& .MuiOutlinedInput-root": {
            backgroundColor: 'white'
        },
        "& *": {
            align: "center",
            textAlign: "center",
        },
    },
    input: {
        backgroundColor: 'white'
    },
    selected: {
        height: 100,
        width: 73,
        border: `1px solid #f4f4f4`,
        backgroundColor: '#ffffff',
        borderRadius: 5,
        position: "relative",
        marginLeft: 10,
        marginRight: 10
    },
    title: {
        fontSize: 25,
        paddingBottom: theme.spacing(1),
    },
    subtitle: {
        color: theme.palette.action.cancel,
    },
    spacer: { width: 20 },
    dividerMargin: {
        marginLeft: 30,
        marginRight: 30
    },
    inactive: {
        "& > *": { color: "lightgrey !important" },
        "& > td > *": { color: "lightgrey !important" },
    },
    actions: {
        display: "flex",
        paddingRight: theme.spacing(0),
        paddingBottom: theme.spacing(2),
        paddingTop: theme.spacing(2),
        justifyContent: "flex-end",
    },
    cancel: {
        marginRight: theme.spacing(2),
    },
    buttons: {
        width: 190
    },
    row: {
        height: 55,
    },
}));

export default function PopSingleTeam({ occurrenceHook, parameters, open, handleCancel, executeAction }) {
    // Styles
    const classes = useStyles();

    const activeTime = occurrenceHook.activeOccurrenceTime;
    const initValue = () => {
        let min = occurrenceHook.sanatizeUnit(occurrenceHook.cronometerValue.getMinutes());
        let sec = occurrenceHook.sanatizeUnit(occurrenceHook.cronometerValue.getSeconds());
        return `${min}:${sec}`;
    }
    const [timeValue, setTimeValue] = useState(initValue());
    const isTimerValid = (timer) => {

        if (timer.length !== 5 || timer[2] !== ':') {
            return false
        }
        for (let index = 0; index < timer.length; index++) {

            if (index !== 2 && isNaN(timer[index])) {

                return false;
            }

        }
        return true

    }
    const [timerValid, setTimerValid] = useState(isTimerValid(timeValue));

    const selectSinglePlayer = (player, team) => {
        occurrenceHook.selectSinglePlayer(player, team);
    }

    const selected = occurrenceHook.selectedPlayers[0];
    const team = occurrenceHook.teamSelected.id === occurrenceHook.match.home.id ? occurrenceHook.match.home : occurrenceHook.match.away;

    const execute = () => {
        if (executeAction) {
            occurrenceHook.selectedOccurrence.occurrenceTime = timeValue;
            executeAction(parameters.type);
            handleCancel();
        }
    }

    

    return (
        <div>
            <Dialog
                classes={{ paper: classes.dialogPaper }}
                open={open}
                onClose={handleCancel}
                maxWidth="sm"
                disableBackdropClick={true}
            >
                <CloseButton onClick={handleCancel} />
                <Grid
                    container
                    className={classes.root}
                    justify="flex-start"
                    direction="column"

                >
                    {/* Header */}
                    <PopHeader type={parameters.type} period={activeTime}></PopHeader>

                    {/* Body */}
                    <Grid container direction="row" alignItems="flex-start" justify="space-around" className={classes.body}>

                        <Grid item className={classes.teamBox}>
                            <PopTeamBody
                                team={team}
                                title='time mandante'
                                showReserves={parameters.showReserves}
                                showStarters={parameters.showStarters}
                                showTechnical={parameters.showTechnical}
                                setPlayer={selectSinglePlayer}>
                            </PopTeamBody>
                        </Grid>

                    </Grid>

                    {/* BigBox */}
                    <PopBigBox timeValue={timeValue} setTimeValue={setTimeValue} handleTimerValid={setTimerValid} >
                        {parameters.showSelected &&
                            <div className={classes.selected}>
                                <FCard values={selected}>
                                    <PlayerPopCard
                                        occurrenceHook={occurrenceHook}
                                        player={selected}
                                        team={team}
                                    ></PlayerPopCard>
                                </FCard>
                            </div>
                        }
                    </PopBigBox>

                    <div className={classes.actions}>
                        <FButton thin={true}
                            onClick={handleCancel}
                            className={classes.buttons}
                            type="cancel">
                            Cancelar
                        </FButton>
                        <div className={classes.spacer}></div>
                        <FButton
                                disabled={(!selected && timerValid)|| !timerValid}
                            thin={true}
                            className={classes.buttons}
                            type="main"
                            onClick={execute}
                        >
                            Confirmar
                        </FButton>
                    </div>
                </Grid>
            </Dialog>
        </div>
    )
}