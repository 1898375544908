import React, { useState, useEffect, useContext } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import { Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import clsx from 'clsx';

// My components
import FButton from '../../../../../components/button/FButton';
import ShieldStepIcon from '../../../../../assets/images/active.png';
import functions from '../../../../../utils/functions';
import FCheckBox from '../../../../../components/checkbox/FCheckBox';
import useSearchPlayerToAdd from './hooks/searchplayertoadd.hook';
import Loading from '../../../../../components/loading/loading';
import { MatchContext } from '../../../../../components/contexts/match.provider';
import constants from '../../../../../utils/constants';
import FSelect from '../../../../../components/inputs/FSelect';
import CloseButton from '../../../../../components/button/CloseButton';

// My Services
import TeamServices from '../../../../../services/team.services';
import Alert from '../../../../../components/pops/Alert';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(2),
  },
  dialogPaper: {
    position: 'relative',
    paddingTop: 10,
    [theme.breakpoints.up('lg')]: {
      maxWidth: 850,
    },
  },
  table: {
    width: '100%',
    minWidth: 550,
    height: '40vh',
    overflow: 'auto',
  },
  number: {
    minWidth: 45,
  },
  inactive: {
    '& > *': { color: 'lightgrey !important' },
    '& > td > *': { color: 'lightgrey !important' },
  },
  nickname: {
    fontWeight: 500,
    fontSize: 15,
  },
  nicknamecell: {
    width: 110,
    maxWidth: 110,
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
    '& h4': {
      width: '72%',
      overflow: 'hidden',
    },
  },
  nicknameOnName: {
    fontSize: 15,
    textTransform: 'lowercase',
    '&:first-letter': {
      textTransform: 'capitalize',
    },
    display: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'block',
    },
  },
  namecell: {
    paddingTop: 10,
    paddingBottom: 10,
    maxWidth: 230,
    wordBreak: 'break-word',
  },
  position: {
    padding: 5,
    paddingRight: 10,
    paddingLeft: 10,
    minWidth: 200,
  },
  cell: {
    minWidth: 60,
  },
  actions: {
    display: 'flex',
    paddingRight: theme.spacing(0),
    paddingBottom: theme.spacing(2),
    justifyContent: 'space-between',
  },
  cancel: {
    marginRight: theme.spacing(2),
  },
  buttons: {
    minWidth: 215,
  },
  row: {},
}));

export default function PopAddPlayer({
  open,
  closeHandler,
  updateCaller,
  match,
  team,
  addType,
  step,
}) {
  // Styles
  const classes = useStyles();

  // Services
  const teamServices = TeamServices();

  // Contexts
  const mainContext = useContext(MatchContext);

  // States
  const initialCriteria = () => ({
    Sentence: '',
    IdCompetition: mainContext.match.competition.id,
    IdTeam: team.id,
  });
  const initialValue = () => ({
    id: team.id,
    matchId: mainContext.match.id,
    competitionId: mainContext.match.competition.id,
    players: [],
  });

  const [criteria, setCriteria] = useState(initialCriteria);
  const [loadingSave, setLoadingSave] = useState(false);
  const [loadingPlayers, players] = useSearchPlayerToAdd(criteria);
  const [selected, setSelected] = useState(initialValue);
  const [message, setMessage] = useState(null);
  const [playerOrMembers, setPlayersOrMembers] = useState(players);
  const [items, setItems] = useState([]);

  useEffect(() => {
    setPlayersOrMembers(players);
  }, [players]);

  //------------------------------------------------------
  // Se o tipo a ser adicionado é tipo PLAYER então a lista
  // a ser atualizada é a de player senão a de membros técnicos
  //------------------------------------------------------

  const listToUpdate =
    addType === constants.AddType.PLAYER ? team.players : team.technicalMembers;

  const title = addType === constants.AddType.PLAYER ? 'atleta' : 'membro';

  // METHODS
  const handleAddPlayer = async () => {
    setLoadingSave(true);
    let response = await teamServices.addPlayer(match, team, items);
    if (response.valid) {
      //------------------------------------------------------
      // Atualiza os jogadores no time
      //------------------------------------------------------
      listToUpdate.push.apply(listToUpdate, response.result.players);
      mainContext.updateMatch();

      setMessage(
        addType === constants.AddType.PLAYER
          ? constants.ADDPLAYER
          : constants.ADDTECHNICAL
      );

      //------------------------------------------------------
      // Atualiza os players visiveis para que não sejam
      // adicionados novamente
      //------------------------------------------------------
      setPlayerOnCompetition(response.result.players);

      //------------------------------------------------------
      //Atualiza o chamador
      //------------------------------------------------------
      if (updateCaller) updateCaller();
    }
    setLoadingSave(false);
  };

  const handleSearch = (event) => {
    setCriteria({
      IdTeam: team.id,
      Sentence: event.target.value,
      IdCompetition: mainContext.match.competition.id,
    });
  };

  const findCompetition = (competition) => {
    return competition !== null && competition !== undefined;
  };

  const isDisabled = (player) => {
    if (findCompetition(player.competition)) return true;

    if (addType === constants.AddType.PLAYER && player.position.id === 0)
      return true;

    if (
      addType === constants.AddType.TECHNICAL &&
      player.technicalFunction.id === 0
    ) {
      return true;
    }

    return false;
  };

  const addOrRemove = (checked, player) => {
    const newSelected = selected.players.filter((p) => p.id !== player.id);
    if (checked) newSelected.push(player);
    setSelected({ ...selected, players: [...newSelected] });
    setItems((prev) => newSelected);
  };

  const clearAndClose = () => {
    setCriteria(initialCriteria);
    setSelected(initialValue);
    closeHandler();
  };

  const setPlayerOnCompetition = (addPlayers) => {
    let competition = { id: mainContext.match.competition.id };

    addPlayers.forEach((p) => {
      let player = players.find((x) => x.id === p.id);
      if (player) {
        player.competition = competition;
        addOrRemove(false, player);
      }
    });
  };

  const updatePlayerOrMember = (player) => {
    setPlayersOrMembers(
      playerOrMembers.map((item) => (item.id === player.id ? player : item))
    );
  };

  const handleOk = () => {
    clearAndClose();
    setMessage(null);
  };

  const setDefaultPosition = (player) => {
    player.position = player.position
      ? constants.positions
          .find((modality) => modality.modalityId === team.modality.id)
          .positions.find((pos) => pos.id === player.position.id)
      : {
          id: 1,
          name: 'Goleiro',
        };
    return player.position;
  };

  const setDefaultTechnincalFunction = (player) => {
    player.technicalFunction =
      player.technicalFunction === undefined
        ? constants.technicalFunctions[0]
        : player.technicalFunction;

    return player.technicalFunction;
  };

  const isLoading = () => loadingPlayers || loadingSave;

  return (
    <div>
      {/* Alerta de Sucesso */}
      <Alert title="Adicionar" handleOk={handleOk} message={message} />

      <Dialog
        classes={{ paper: classes.dialogPaper }}
        maxWidth="md"
        fullWidth={true}
        open={open}
        onClose={closeHandler}
        disableBackdropClick={true}
      >
        <Loading isLoading={isLoading()} />
        <CloseButton onClick={clearAndClose} />
        <Grid
          container
          className={classes.root}
          justify="flex-start"
          direction="column"
        >
          {/* Cabeçalho */}
          <Grid
            item
            container
            direction="row"
            spacing={1}
            alignItems="flex-start"
          >
            <Grid item>
              <img src={ShieldStepIcon} alt=""></img>
            </Grid>
            <Grid item>
              <Typography variant="h3">{team.name}</Typography>
              <Typography variant="body1">{team.colors}</Typography>
              <Typography variant="h5">
                {step === 0 && <span>Mandante</span>}
                {step === 1 && <span>Visitante</span>}
              </Typography>
            </Grid>
          </Grid>

          {/* Campo de Busca */}
          <Grid item>
            <Box pt={5}>
              <Grid
                container
                direction="row"
                alignItems="center"
                justify="space-between"
              >
                <Grid item xs={5}>
                  <Typography variant="h3">
                    Inscrever {title} ao time
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    placeholder="Nome, apelido, e-mail"
                    onChange={handleSearch}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>

          <Divider></Divider>

          <Grid item>
            <TableContainer className={classes.table}>
              <Table aria-label="Scalation Table">
                <TableHead>
                  <TableRow className={classes.row}>
                    <TableCell className={classes.nicknamecell}>
                      Apelido
                    </TableCell>
                    <TableCell>Nome</TableCell>
                    {addType === constants.AddType.TECHNICAL && (
                      <TableCell className={classes.position}>Função</TableCell>
                    )}
                    {addType === constants.AddType.PLAYER && (
                      <TableCell className={classes.position}>
                        Posição
                      </TableCell>
                    )}
                    {addType === constants.AddType.PLAYER && (
                      <TableCell align="center">N°</TableCell>
                    )}
                    <TableCell align="center">Documento</TableCell>
                    <TableCell align="center">Adicionar</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {playerOrMembers.map((player) => (
                    <Tooltip
                      key={player.id}
                      title={
                        findCompetition(player.competition)
                          ? 'O jogador já está na competição'
                          : ''
                      }
                      aria-label="add"
                    >
                      <TableRow
                        className={clsx(classes.row, {
                          [classes.inactive]: findCompetition(
                            player.competition
                          ),
                        })}
                      >
                        {/* Apelido */}
                        <TableCell className={classes.nicknamecell}>
                          <Typography variant="h4" className={classes.nickname}>
                            {functions.setNicknameWith(player, 8)}
                          </Typography>
                        </TableCell>

                        {/* Nome do Jogador */}
                        <TableCell className={classes.namecell}>
                          <Typography
                            variant="h4"
                            className={classes.nicknameOnName}
                          >
                            {functions.setNicknameWith10(player)}
                          </Typography>
                          {player.name}
                          <Typography variant="body2">
                            <strong>Nasc.: </strong>
                            {functions.formatDate(player.birthDate)}
                          </Typography>
                        </TableCell>

                        {addType === constants.AddType.PLAYER && (
                          <TableCell className={classes.position}>
                            <FSelect
                              disabled={findCompetition(player.competition)}
                              value={setDefaultPosition(player)}
                              collection={
                                constants.positions.find(
                                  (x) => x.modalityId === team.modality.id
                                ).positions
                              }
                              onChange={(value) => {
                                player.position = value;
                                updatePlayerOrMember(player);
                              }}
                            ></FSelect>
                          </TableCell>
                        )}

                        {addType === constants.AddType.TECHNICAL && (
                          <TableCell className={classes.position}>
                            <FSelect
                              disabled={findCompetition(player.competition)}
                              value={setDefaultTechnincalFunction(player)}
                              collection={constants.technicalFunctions}
                              onChange={(value) => {
                                player.technicalFunction = value;
                                updatePlayerOrMember(player);
                              }}
                            ></FSelect>
                          </TableCell>
                        )}

                        {/* Número da Camisa */}
                        {addType === constants.AddType.PLAYER && (
                          <TableCell className={classes.number} align="center">
                            {player.defaultNumber}
                          </TableCell>
                        )}

                        {/* Data de Nascimento e RG */}
                        <TableCell className={classes.cell} align="center">
                          {player.doc}
                        </TableCell>

                        <TableCell className={classes.cell} align="center">
                          <FCheckBox
                            onClick={(event) => {
                              addOrRemove(event.target.checked, player);
                            }}
                            disabled={isDisabled(player)}
                          />
                        </TableCell>
                      </TableRow>
                    </Tooltip>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          <DialogActions className={classes.actions}>
            <div>
              <Typography variant="h5" className={classes.info}>
                Para incluir um atleta, é obrigatório que ele tenha informado o
                código dessa equipe <strong>({team.code})</strong> no seu
                cadastro
              </Typography>
            </div>
            <div className={classes.buttons}>
              <FButton
                thin
                className={classes.cancel}
                onClick={clearAndClose}
                type="cancel"
              >
                Cancelar
              </FButton>
              <FButton
                thin
                onClick={() => handleAddPlayer()}
                type="main"
                disabled={selected.players.length <= 0 || isLoading()}
              >
                Adicionar
              </FButton>
            </div>
          </DialogActions>
        </Grid>
      </Dialog>
    </div>
  );
}
