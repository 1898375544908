import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import RefereeTable from "../escalation/components/referees/referee.table";
import Divider from "@material-ui/core/Divider";

// My Components
import { MatchContext } from "../../../components/contexts/match.provider";
import Fade from "../../../theme/animations/fade.animations";
import FButton from "../../../components/button/FButton";
import ErrorAlert from "../../../components/pops/ErrorAlert";
import constants from "../../../utils/constants";
import Cronometer from "./components/cronometer";
import useRefereeHook from "../escalation/components/hooks/referees.hook";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: 60,
    paddingRight: 60,
    [theme.breakpoints.down('md')]: {
      paddingLeft: 30,
      paddingRight: 30,
    },
    paddingBottom: 40,
    paddingTop: 20,
    maxWidth: 2015,
    margin: '0 auto',
  },
  refereers: {
    justifyContent: 'space-between',
    [theme.breakpoints.up('lg')]: {
      justifyContent: "center",
    }
  },
  divider: {
    background: theme.palette.divider,
    marginTop: 50,
    marginBottom: 50,
  },
  saveChanges: {
    maxWidth: 350,
    alignSelf: "flex-end",
  },
  cronometer: {
    paddingTop: 15,
    maxWidth: 400,
  },
}));

export default function Referees() {
  const mainContext = useContext(MatchContext);
  const refereesHook = useRefereeHook();
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Fade>
        <Grid
          container
          spacing={0}
          direction="row"
          alignItems="flex-start"
          alignContent="center"
          wrap="nowrap"
          className={classes.refereers}
        >
          <Grid item xs={6}>
            <Grid
              container
              direction="column"
              alignItems="stretch"
              justify="center"
              spacing={2}
            >
              <Grid item>
                <Typography variant="h3">Quadro de arbitragem</Typography>
              </Grid>
              <Grid item>
                <RefereeTable refereesHook={refereesHook} mainContext={mainContext}></RefereeTable>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            xs={5}
            container
            direction="column"
            className={classes.cronometer}
            spacing={2}
          >
            <Grid container item className={classes.saveChanges}>
              <FButton
                fullWidth
                thin
                type="secondary"
                onClick={() => {
                  mainContext.updateStep(mainContext.step);
                }}

              >
                Salvar alterações
              </FButton>
            </Grid>
            <Grid item>
              <Cronometer
                clockComment={mainContext.match.clockComment}
              ></Cronometer>
            </Grid>
          </Grid>
        </Grid>
        <Divider className={classes.divider} />
      </Fade>

      <ErrorAlert
        open={mainContext.hasNoCronometerError}
        onClose={() => mainContext.setHasNoCronometerError(false)}
        message={constants.HASNOCRONOMETER}
      ></ErrorAlert>
    </div>
  );
}
