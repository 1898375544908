import React, { useState, useLayoutEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import { Typography } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";

// My Components
import FButton from "../../../../../components/button/FButton";
import FSelect from "../../../../../components/inputs/FSelect";
import CloseButton from '../../../../../components/button/CloseButton'

import constants from "../../../../../utils/constants";

const useStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(2),
    },
    dialogPaper: {
        position: 'relative',
        paddingTop: 10,
        [theme.breakpoints.up("lg")]: {
            maxWidth: 850,
            minWidth: 400,
        },
    },
    body: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    title: {
        paddingBottom: theme.spacing(1),
    },
    spacer: { width: 30 },
    inactive: {
        "& > *": { color: "lightgrey !important" },
        "& > td > *": { color: "lightgrey !important" },
    },
    actions: {
        display: "flex",
        paddingRight: theme.spacing(0),
        paddingBottom: theme.spacing(2),
        justifyContent: "space-between",
    },
    cancel: {
        marginRight: theme.spacing(2),
    },
    buttons: {
        minWidth: 215,
    },
    row: {
        height: 55,
    },
}));

export default function PopAddTime({
    occurrencePeriodsHook,
    open,
    handleCancel,
    handleOk,
}) {
    // Styles
    const classes = useStyles();
    const [period, setPeriod] = useState({});
    const [timeEvents, setTimeEvents] = useState(() => constants.gameTimeEvents);
    const [timeTypes, setTimeTypes] = useState(() => constants.timeType);

    const filterPenaltis = () => {
        var exists = occurrencePeriodsHook.periods.find(
            (time) => time.timeType.id === constants.timeType[2].id
        );

        setTimeTypes(prev => exists !== undefined ? constants.timeType.filter(x => x.id !== exists.timeType.id) : constants.timeType);
    }

    useLayoutEffect(() => {
        var exists = occurrencePeriodsHook.periods.find(
            (time) => time.timeType.id === constants.timeType[2].id
        );

        setTimeTypes(prev => exists ? constants.timeType.filter(x => x.id !== exists.timeType.id) : constants.timeType);

    }, [occurrencePeriodsHook.periods])

    const handleSetTimeType = (value) => {
        let gameTime = value.id === 3 ? constants.gameTimeEvents[0] : undefined;
        setPeriod({ timeType: value, gameTime: gameTime });
        filterPeriod(value);
    };

    const filterPeriod = (timeType) => {
        //-----------------------------------------------
        // Se o tempo é diferente de penaltis
        // então eu busco os períodos disponíveis
        //-----------------------------------------------
        if (timeType && timeType.id !== 3) {
            var exists = occurrencePeriodsHook.periods.filter(
                (time) => time.timeType.id === timeType.id
            );

            if (exists) {
                //-----------------------------------------------
                // para cada período encontrado, eu preciso
                // remover suas opções da combo
                //-----------------------------------------------
                setTimeEvents([]);
                let items = [];

                constants.gameTimeEvents.forEach((element) => {
                    let allreadyInList = exists.find((x) => x.gameTime.id === element.id);
                    if (!allreadyInList) {
                        items.push(element);
                    }
                });

                setTimeEvents(items);
            }
        }
    };

    const handleSetGameTime = (value) => {
        setPeriod({ ...period, gameTime: value });
    };

    const ok = () => {
        filterPenaltis();
        clear();
        let newPeriod = {
            ...period,
            matchId: occurrencePeriodsHook.match.id,
            competitionId: occurrencePeriodsHook.match.competition.id,
            occurrenceExtraTime: "",
            occurrenceHourEnd: "--:--",
            occurrenceHourStart: "--:--",
        };
        handleOk(newPeriod);
        occurrencePeriodsHook.dispatch({ type: 'addPeriod', payload: newPeriod });
    };

    const cancel = () => {
        filterPenaltis();
        clear();
        handleCancel();
    };

    const isEnabled = () => {
        return (
            (period.timeType &&
                period.timeType.id &&
                period.gameTime &&
                period.gameTime.id) ||
            isPenaltis()
        );
    };

    const isPenaltis = () => {
        return period.timeType && period.timeType.id === 3;
    };

    const clear = () => {
        setPeriod({});
    };

    return (
        <Dialog
            classes={{ paper: classes.dialogPaper }}
            open={open}
            onClose={handleCancel}
            maxWidth="sm"
            disableBackdropClick={true}
        >
            <CloseButton onClick={cancel} />
            <Grid
                container
                className={classes.root}
                justify="flex-start"
                direction="column"
            >
                <Typography variant="h4" className={classes.title}>
                    Adicionar Tempo
                </Typography>

                <Divider></Divider>
                <Grid
                    className={classes.body}
                    item
                    container
                    direction="column"
                    spacing={2}
                >
                    <Grid item>
                        <FSelect
                            placeHolder="Selecione o tempo"
                            value={period.timeType || ""}
                            collection={timeTypes}
                            onChange={(event) => {
                                handleSetTimeType(event);
                            }}
                        ></FSelect>
                    </Grid>
                    <Grid item>
                        {!isPenaltis() && <FSelect
                            disabled={isPenaltis()}
                            placeHolder="Selecione o período"
                            value={period.gameTime || ""}
                            collection={timeEvents}
                            onChange={(event) => {
                                handleSetGameTime(event);
                            }}
                        ></FSelect>
                        }
                    </Grid>
                </Grid>
                <div className={classes.actions}>
                    <FButton thin={true} fullWidth onClick={cancel} type="cancel">
                        Cancelar
                    </FButton>
                    <div className={classes.spacer}></div>
                    <FButton
                        disabled={!isEnabled()}
                        thin={true}
                        fullWidth
                        onClick={ok}
                        type="main"
                    >
                        Confirmar
                    </FButton>
                </div>
            </Grid>
        </Dialog>
    );
}
