import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";

// My Components
import FButton from "../button/FButton";

function Confirm({ title, message, open, handleAbort, handleOk }) {

    const handleClose = () => {
        if (handleAbort) handleAbort();
    };

    return (
        <div>
            <Dialog open={open} disableBackdropClick={true} maxWidth="sm" fullWidth={true}>
                <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {message}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <FButton thin onClick={handleClose} type="cancel">
                        Cancelar
          </FButton>
                    <FButton thin onClick={handleOk} type="primary">
                        Confirmar
          </FButton>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default Confirm;
