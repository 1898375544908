import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import clsx from "clsx";

import shieldActive from "../../../../assets/images/active.png";
import shieldInactive from "../../../../assets/images/inactive.png";
import arbActive from "../../../../assets/images/arbactive.png";
import arbInactive from "../../../../assets/images/arbInative.png";

const useStyles = makeStyles((theme) => ({
  principal: {
    zIndex: 1,
    color: "#fff",
    width: 50,
    height: 50,
    display: "flex",
    cursor: "pointer",
  },
}));

function ShieldStepIcon(props) {
  const classes = useStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.principal, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {(props.icon === 1 || props.icon === 2) && props.active && (
        <div>
          <img src={shieldActive} alt=""></img>
        </div>
      )}

      {(props.icon === 1 || props.icon === 2) && !props.active && (
        <div>
          <img src={shieldInactive} alt=""></img>
        </div>
      )}

      {props.icon === 3 && props.active && (
        <div>
          <img src={arbActive} alt=""></img>
        </div>
      )}
      {props.icon === 3 && !props.active && (
        <div>
          <img src={arbInactive} alt=""></img>
        </div>
      )}
    </div>
  );
}

ShieldStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

export default ShieldStepIcon;
