import { useState, useContext } from "react";
import { api } from "../../../../../services/api.services";
//import { OccurrenceContext } from "../../../../../components/contexts/occurrences.provider";
import { MatchContext } from "../../../../../components/contexts/match.provider";


export default function useRefereeReportHook() {

    // const occurrenceContext = useContext(OccurrenceContext);
    const mainContext = useContext(MatchContext);

    const [showReport, setShowReport] = useState(() => false);
    const [loading, setLoading] = useState(() => false);

    const save = async (comment) => {
        setLoading(true);
        mainContext.match.clockComment.comment = comment
        let matchComment = {
            matchId: mainContext.match.id,
            competitionId: mainContext.match.competition.id,
            clockComment: mainContext.match.clockComment
        };
        let response = await api.put("match/updatecomment", matchComment);
        if (response.status === 200) {
            mainContext.updateMatch();
        }

        setLoading(false);
        setShowReport(false);
    }

    return {
        loading,
        comment: mainContext.match.clockComment?.comment,
        showReport,
        setShowReport,
        save
    }
}