import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';
import OutlinedInput from '@material-ui/core/OutlinedInput';
//import InputAdornment from "@material-ui/core/InputAdornment";
//import Undo from "@material-ui/icons/Undo";
//import IconButton from "@material-ui/core/IconButton";

// My custom components
import constants from '../../../../../utils/constants';
//import FButton from "../../../../components/button/FButton";
//import { MatchContext } from "../../../../components/contexts/match.provider";
//import PopAddReferees from "../../pops/addReferees/index";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 700,
  },
  row: {
    padding: '6px 6px',
    height: 55,
  },
  cellname: {
    minWidth: 220,
  },
  cellFunction: {
    paddingLeft: 20,
  },
  defaultNumber: {
    width: 60,
    height: 6,
    padding: '6px 6px',
  },
  ok: {
    color: 'green',
    fontWeight: 600,
  },
  nook: {
    color: 'red',
    fontWeight: 600,
  },
  gridSpacer: {
    paddingTop: theme.spacing(5),
  },
  na: {
    color: 'grey',
    fontWeight: 600,
  },
  search: {
    color: 'lightgrey',
  },
}));

export default function RefereeTable({ refereesHook, mainContext }) {
  const classes = useStyles();
  //const mainContext = useContext(MatchContext);
  //const [openAddReferee, setOpenAddReferee] = useState(false);

  //Methods;
  // const openAddRefereeHandler = () => {
  //   setOpenAddReferee(true);
  // };

  // const closeAddHandler = () => {
  //   setOpenAddReferee(false);
  // };

  // const update = () => {
  //   mainContext.updateMatch();
  // };

  const getReferee = (id) => {
    return refereesHook.referees.find((x) => x.refereeFunction.id === id);
  };

  const getRefereeName = (id) => {
    let referee = getReferee(id);
    return referee !== undefined ? referee.name : '';
  };

  return (
    <div>
      {/* Pop para adição de Jogador */}
      {/* <PopAddReferees
        match={mainContext.match}
        updateCaller={update}
        open={openAddReferee}
        closeHandler={closeAddHandler}
      ></PopAddReferees> */}

      <Grid container direction="column" className={classes.root}>
        <Grid
          item
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          {/* <Grid item xs={5} container justify="flex-end">
            <FButton fullWidth type="primary" onClick={openAddRefereeHandler}>
              Alterar membro da lista
            </FButton>
          </Grid> */}
        </Grid>
        <Grid item className={classes.gridSpacer}>
          <TableContainer>
            <Table aria-label="Scalation Table">
              <TableHead>
                <TableRow>
                  <TableCell className={classes.cellname}>Nome</TableCell>
                  <TableCell className={classes.cellFunction}>Função</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {constants.refereesFunctions.map(
                  (refereeFunction) =>
                    refereeFunction.id > 0 && (
                      <TableRow
                        key={refereeFunction.id}
                        className={classes.row}
                      >
                        {/* Nome do Jogador */}
                        <TableCell className={classes.cellname}>
                          <OutlinedInput
                            fullWidth
                            value={
                              refereeFunction.sigla === 'AN'
                                ? mainContext.user.name
                                : getRefereeName(refereeFunction.id)
                            }
                            variant="outlined"
                            disabled={
                              refereeFunction.id === 5 ||
                              mainContext.isMatchDone
                            }
                            placeholder={refereeFunction.name}
                            // endAdornment={
                            //   getRefereeHasChanged(refereeFunction.id) && (
                            //     <InputAdornment position="end">
                            //       <IconButton
                            //         aria-label="toggle password visibility"
                            //         onClick={(event) => {
                            //           refereesHook.reset(
                            //             getReferee(refereeFunction.id)
                            //           );
                            //         }}
                            //         edge="end"
                            //       >
                            //         <Undo className={classes.search} />
                            //       </IconButton>
                            //     </InputAdornment>
                            //   )
                            // }
                            onPaste={(event) => {
                              refereesHook.changeName(
                                event.target.value,
                                refereeFunction.id
                              );
                            }}
                            onChange={(event) => {
                              refereesHook.changeName(
                                event.target.value,
                                refereeFunction.id
                              );
                            }}
                          />
                        </TableCell>

                        {/* Função */}
                        <TableCell className={classes.cellFunction}>
                          {refereeFunction.name}
                        </TableCell>
                      </TableRow>
                    )
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </div>
  );
}
