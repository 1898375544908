import React, { useMemo } from 'react';

// My components
import useOccurrenceHook from '../occurrences/components/hooks/occorrence.hook';
import ErrorAlert from '../../../components/pops/ErrorAlert';
import TimeEvents from './components/bigHeader/timeEvents';
import MiniScoreBoard from './components/smallHeader/miniScoreboard';
import Actions from './components/actions';
import Body from './components/body';
import useOccurrencePeriodsHook from './components/hooks/occurrencePeriods.hooks';
import ButtonToTop from '../../../components/button/ButtonToTop';

export default function Occurrences({ mainContext, occurrenceContext }) {
  const occurrenceHook = useOccurrenceHook();
  const occurrencePeriodsHook = useOccurrencePeriodsHook(mainContext.match);

  // ---------------------------------------------------
  // Memos
  // ---------------------------------------------------

  const timeEventsMemo = useMemo(() => {
    return (
      <div>
        <TimeEvents
          occurrenceHook={occurrenceHook}
          occurrenceContext={occurrenceContext}
          occurrencePeriodsHook={occurrencePeriodsHook}
          mainContext={mainContext}
        ></TimeEvents>
      </div>
    );
  }, [occurrenceContext, occurrencePeriodsHook, occurrenceHook, mainContext]);

  const scoreBoardMemo = useMemo(() => {
    return (
      <div>
        <MiniScoreBoard
          mainContext={mainContext}
          occurrenceContext={occurrenceContext}
          timeEvents={timeEventsMemo}
          occurrencePeriodsHook={occurrencePeriodsHook}
        ></MiniScoreBoard>
      </div>
    );
  }, [mainContext, occurrenceContext, occurrencePeriodsHook, timeEventsMemo]);

  return (
    <div style={{ maxWidth: '1950px', margin: '0 auto' }}>
      {/* Occurrences ScoreBoard */}
      {scoreBoardMemo}

      {/* Occurrences Actions */}
      <Actions
        occurrenceHook={occurrenceHook}
        occurrenceContext={occurrenceContext}
        mainContext={mainContext}
        styles={{ height: '100%' }}
      ></Actions>

      {/* Occurrences Body */}
      <Body occurrenceHook={occurrenceHook} mainContext={mainContext}></Body>

      {occurrenceContext.error && (
        <ErrorAlert
          open={occurrenceContext.error}
          onClose={occurrenceContext.setError}
        />
      )}

      <ButtonToTop />
    </div>
  );
}
