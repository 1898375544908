import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import { Typography } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';

// My Components
import FButton from '../../../../../components/button/FButton';
import FCard from '../../../../../theme/animations/fcard.animations';
import PlayerPopCard from '../../components/cards/player.pop.card';
import CloseButton from '../../../../../components/button/CloseButton';

import PopHeader from '../../components/popHeader';
import PopTeamBody from '../../components/popTeamBody';
import PopBigBox from '../../components/popBigBox';

import functions from '../../../../../utils/functions';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(2),
  },
  dialogPaper: {
    position: 'relative',
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    maxWidth: 1050,
    width: '100%',
    paddingTop: 10,
  },
  body: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    alignItems: 'flex-start',
  },
  fixedItem: {
    maxHeight: '67vh',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  teamBox: {
    width: '49%',
    textAlign: 'center',
  },
  golTitle: {
    height: 35,
    width: 85,
    border: `1px solid #cccccc`,
    backgroundColor: '#ffffff',
    borderRadius: 5,
    position: 'relative',
    fontSize: 24,
  },
  timer: {
    '& .MuiOutlinedInput-root': {
      backgroundColor: 'white',
    },
    '& *': {
      align: 'center',
      textAlign: 'center',
    },
  },
  input: {
    backgroundColor: 'white',
  },
  selected: {
    height: 100,
    width: 73,
    border: `1px solid #f4f4f4`,
    backgroundColor: '#ffffff',
    borderRadius: 5,
    position: 'relative',
    marginLeft: 10,
    marginRight: 10,
  },
  title: {
    fontSize: 25,
    paddingBottom: theme.spacing(1),
  },
  subtitle: {
    color: theme.palette.action.cancel,
  },
  spacer: { width: 20 },
  dividerMargin: {
    marginLeft: 30,
    marginRight: 30,
  },
  inactive: {
    '& > *': { color: 'lightgrey !important' },
    '& > td > *': { color: 'lightgrey !important' },
  },
  actions: {
    display: 'flex',
    paddingRight: theme.spacing(0),
    paddingTop: theme.spacing(2),
    justifyContent: 'flex-end',
  },
  cancel: {
    marginRight: theme.spacing(2),
  },
  buttons: {
    width: 190,
  },
  row: {
    height: 55,
  },
}));

export default function PopGol({
  occurrenceHook,
  executeActionPop,
  parameters,
  open,
  handleCancel,
}) {
  // Styles
  const classes = useStyles();

  const activeTime = occurrenceHook.activeOccurrenceTime;
  const [playerGol, setPlayerGol] = useState(undefined);
  const [playerAssistance, setPlayerAssistance] = useState(undefined);

  const initValue = () => {
    if (occurrenceHook.selectedOccurrence) {
      return occurrenceHook.selectedOccurrence.occurrenceTime;
    }
    return occurrenceHook.getCalculatedCronometer();
  };
  const [timeValue, setTimeValue] = useState(initValue());
  const isTimerValid = (timer) => {
    if (timer.length !== 5 || timer[2] !== ':') {
      return false;
    }
    for (let index = 0; index < timer.length; index++) {
      if (index !== 2 && isNaN(timer[index])) {
        return false;
      }
    }
    return true;
  };
  const [timerValid, setTimerValid] = useState(isTimerValid(timeValue));

  const setPlayer = (player, team) => {
    if (!isSameTeam(team)) return;

    // Remove da seleção caso já esteja selecionado
    if (playerGol && playerGol.id === player.id) {
      setPlayerGol((prev) => undefined);
      occurrenceHook.selectPlayer(player, team);
      return;
    }

    // Remove da seleção caso já esteja selecionado
    if (playerAssistance && playerAssistance.id === player.id) {
      setPlayerAssistance((prev) => undefined);
      occurrenceHook.selectPlayer(player, team);
      return;
    }

    if (
      occurrenceHook.selectedPlayers.length === 0 ||
      (occurrenceHook.selectedPlayers.length === 1 && playerAssistance)
    ) {
      player.isGol = true;
      setPlayerGol((prev) => player);
    }
    if (occurrenceHook.selectedPlayers.length === 1 && playerGol) {
      delete player.isGol;
      setPlayerAssistance((prev) => player);
    }
    if (occurrenceHook.selectedPlayers.length < 2)
      occurrenceHook.selectPlayer(player, team);
  };

  const getPlayer = (index) => {
    return occurrenceHook.selectedPlayers[index];
  };

  const isSameTeam = (team) => {
    return (
      (occurrenceHook.teamSelected &&
        team.id === occurrenceHook.teamSelected.id) ||
      !occurrenceHook.teamSelected
    );
  };

  const execute = () => {
    if (executeActionPop) {
      let occurrence = occurrenceHook.selectedOccurrence
        ? occurrenceHook.selectedOccurrence
        : {};
      occurrence.occurrenceType = functions.getGolOccurrenceType();
      occurrence.occurrenceTime = timeValue;

      handleCancel();
      executeActionPop(occurrence);
    }
  };

  return (
    <div>
      <Dialog
        classes={{ paper: classes.dialogPaper }}
        open={open}
        onClose={handleCancel}
        maxWidth="sm"
        disableBackdropClick={true}
      >
        <CloseButton onClick={handleCancel} />
        <Grid
          container
          className={classes.root}
          justify="flex-start"
          direction="column"
        >
          <PopHeader type={parameters.type} period={activeTime}></PopHeader>
          <Grid item container className={classes.fixedItem}>
            <Grid
              container
              direction="row"
              alignItems="center"
              justify="space-around"
              className={classes.body}
            >
              <Grid item className={classes.teamBox}>
                <PopTeamBody
                  team={occurrenceHook.match.home}
                  title="time mandante"
                  setPlayer={setPlayer}
                ></PopTeamBody>
              </Grid>
              <Divider orientation="vertical" flexItem />
              <Grid item className={classes.teamBox}>
                <PopTeamBody
                  team={occurrenceHook.match.away}
                  title="time visitante"
                  setPlayer={setPlayer}
                ></PopTeamBody>
              </Grid>
            </Grid>

            {/* BigBox */}
            <PopBigBox
              timeValue={timeValue}
              setTimeValue={setTimeValue}
              handleTimerValid={setTimerValid}
            >
              <Grid
                container
                justify="center"
                alignItems="center"
                item
                className={classes.golTitle}
              >
                <Typography variant="body2">Gol</Typography>
              </Grid>

              <div className={classes.selected}>
                <FCard values={playerGol}>
                  <PlayerPopCard
                    occurrenceHook={occurrenceHook}
                    team={occurrenceHook.teamSelected}
                    player={playerGol}
                  ></PlayerPopCard>
                </FCard>
              </div>
              <Divider
                orientation="vertical"
                flexItem
                className={classes.dividerMargin}
              />
              <div className={classes.selected}>
                <FCard values={playerAssistance}>
                  <PlayerPopCard
                    occurrenceHook={occurrenceHook}
                    team={occurrenceHook.teamSelected}
                    player={playerAssistance}
                  ></PlayerPopCard>
                </FCard>
              </div>
              <Grid
                container
                justify="center"
                alignItems="center"
                item
                className={classes.golTitle}
              >
                <Typography variant="body2">Assitência</Typography>
              </Grid>
            </PopBigBox>
          </Grid>

          <div className={classes.actions}>
            <FButton
              thin={true}
              onClick={handleCancel}
              className={classes.buttons}
              type="cancel"
            >
              Cancelar
            </FButton>
            <div className={classes.spacer}></div>
            <FButton
              disabled={(!getPlayer(0) && timerValid) || !timerValid}
              thin={true}
              className={classes.buttons}
              type="main"
              onClick={execute}
            >
              Confirmar
            </FButton>
          </div>
        </Grid>
      </Dialog>
    </div>
  );
}
