import { useState } from "react";
import { api } from "../../../../services/api.services";

export default function useReportHook() {

    const [loading, setLoading] = useState(false);

    const generate = async (type, matchId) => {
        setLoading(prev => true);
        let response = await api.get(`report/export?matchid=${matchId}&documenttypeid=${type}`,
            { responseType: 'blob', headers: { 'Content-Type': 'application/json', 'Accept': 'application/pdf' } });

        const url = window.URL.createObjectURL(new Blob([response.data], { type: "application/pdf" }));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', getFileName(type));
        document.body.appendChild(link);
        link.click();
        setLoading(prev => false);
    }

    const getFileName = type => {
        switch (type) {
            case "1":
                return 'sumula-pre-jogo-vazia.pdf';
            case "2":
                return 'sumula-pre-jogo.pdf';
            case "3":
                return 'sumula-pos-jogo.pdf';
            case "4":
                return 'relatorio-arbitro.pdf';

            default:
                break;
        }
    }

    return {
        loading,
        generate
    }
}