import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import clsx from "clsx";

// My components
import constants from "../../../../../utils/constants";
import PopEditTime from "../../pops/editTime/";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(2),
    },
    box: {
        height: 110,
        width: 160,
        display: "inline-block",
        position: "relative",
        borderRadius: 5,
        backgroundColor: "white",
        borderColor: theme.palette.action.disabled,
        "& input, p, hr, h4": {
            color: theme.palette.text.primary,
        },
        cursor: "pointer",
        "&:hover": {
            backgroundColor: "#fafafa",
        },
    },
    badgeBox: {
        position: "absolute",
        top: 3,
        right: 10,
        color: theme.palette.text.primary,
        width: 18,
        height: 37,
        verticalAlign: "center",
        textAlign: "center",
    },
    activeBox: {
        backgroundColor: theme.palette.action.main,
        borderColor: theme.palette.action.main,
        "&:hover": {
            backgroundColor: theme.palette.action.main,
        },
        "& *": {
            color: "white !important",
        },
    },
    divider: {
        background: theme.palette.divider,
        width: 158,
    },
    goal: {
        fontSize: 22,
    },
    versus: {
        fontSize: 18,
    },
    verticalPadding: {
        paddingTop: 5,
        paddingBottom: 5
    },
    score: {
        padding: "0px !important",
    },
    beginEnd: {
        "& h4": {
            fontWeigth: 100,
            color: theme.palette.action.cancel,
        },
    },
}));

export default function TimeCard({ period, occurrenceContext, occurrencePeriodsHook, occurrenceHook, handleDelete, mainContext, handleCallback }) {
    const classes = useStyles();

    const [showEdit, setShowEdit] = useState(() => false);
    // ------------------------------------------
    // Méthods
    // ------------------------------------------
    const openPopEdit = () => {
        if (!mainContext.isMatchDone) setShowEdit(true);
    };

    const handleActivate = () => {
        occurrencePeriodsHook.setActivePeriod(period);
        occurrenceHook.selectOccurrence('');
    };

    const getGoals = (teamId, enemyTeamId) => {
        let goals = occurrenceContext.getGoalsInfo(period.gameTime.id, period.timeType.id, teamId);
        let golsAgainst = occurrenceContext.golsAgainst(period.gameTime.id, period.timeType.id, enemyTeamId);
        return goals.length + golsAgainst.length;
    };

    const isActive = () => {
        if (!occurrencePeriodsHook.activePeriod.gameTime) return false;
        return period.gameTime.id === occurrencePeriodsHook.activePeriod.gameTime.id
            && period.timeType.id === occurrencePeriodsHook.activePeriod.timeType.id
    };

    const getGameTimeName = () => {
        if (period.timeType.id === 3) return period.timeType.name;
        let gt = constants.gameTimeEvents.find(x => x.id === period.gameTime.id);
        return gt.name;
    }

    return (
        <div>
            <PopEditTime
                occurrencePeriodsHook={occurrencePeriodsHook}
                period={period}
                open={showEdit}
                handleOk={() => setShowEdit(false)}
                handleCancel={() => setShowEdit(false)}
                handleDelete={handleDelete}
            ></PopEditTime>
            <Box
                border={1}
                className={clsx(classes.box, {
                    [classes.activeBox]: isActive(),
                })}
            >
                <Grid
                    className={clsx(classes.badgeBox, {
                        [classes.activeBox]: isActive(),
                    })}
                    container
                    direction="column"
                    alignItems="center"
                    justify="space-between"
                    wrap="nowrap"
                >
                    <Grid item>
                        <EditOutlinedIcon onClick={() => openPopEdit()}></EditOutlinedIcon>
                    </Grid>
                </Grid>
                <Grid
                    container
                    direction="column"
                    alignItems="center"
                    justify="space-between"
                    wrap="nowrap"
                    onClick={handleActivate}
                >
                    <Grid item className={classes.verticalPadding} container direction="column" alignItems="center" >
                        <Grid item>
                            <Typography variant="h4">{getGameTimeName()}</Typography>
                        </Grid>
                        <Grid item className={classes.verticalPadding}>
                            <Typography variant="h5">{period.timeType.name}</Typography>
                        </Grid>
                        <Grid item>
                            <Divider className={classes.divider} />
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        className={classes.score}
                        container
                        justify="center"
                        alignItems="baseline"
                        spacing={1}
                    >
                        <Grid item>
                            <Typography variant="h3" className={classes.goal}>
                                {getGoals(occurrencePeriodsHook.match.home.id, occurrencePeriodsHook.match.away.id)}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="h3" className={classes.versus}>
                                X
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="h3" className={classes.goal}>
                                {getGoals(occurrencePeriodsHook.match.away.id, occurrencePeriodsHook.match.home.id)}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid
                        className={classes.beginEnd}
                        item
                        container
                        justify="center"
                        spacing={1}
                    >
                        <Grid item>
                            <Typography variant="h4">{period.occurrenceHourStart}</Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="h4">-</Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="h4">{period.occurrenceHourEnd}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </div>
    );
}
