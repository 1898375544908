import React, { useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";

// My custom components
import Validators from "../../../../utils/validators";
import FTextInput from "../../../../components/inputs/FTextInput";
import FController from "../../../../components/inputs/FControllerHook";
import FButton from "../../../../components/button/FButton";
import Alert from "../../../../components/pops/Alert";
import Loading from "../../../../components/loading/loading";
import useValidateCode from "../hooks/validateCode.hook";
import AuthLogo from "../../../../components/logos/auth.logo";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    width: 380,
    borderRadius: 10,
  },
  logo: {
    paddingBottom: theme.spacing(4),
  },
  divider: {
    background: theme.palette.divider,
    display: "flex",
    width: "100%",
    //width: "92%",
    // margin: 40,
  },
  email: {
    fontWeight: 800,
  },
  buttons: {
    paddingTop: theme.spacing(6),
  },
  paper: {
    width: 50,
    "& *": {
      align: "center",
      textAlign: "center",
    },
  },
}));

function Code({ cancel, user, setValidated }) {
  const classes = useStyles();
  const [credentials, setCredentials] = useState({});
  const [loading, response] = useValidateCode(credentials);
  const [showPop, setShowPop] = useState(false);
  const valitators = Validators();

  useEffect(() => {
    if (response && response.data) setShowPop(true);
    setValidated(response && response.status === 200);
  }, [response, setValidated]);

  // InputControllers
  const codes = [
    FController(),
    FController(),
    FController(),
    FController(),
    FController(),
  ];

  const handleValidateCode = async (e) => {
    let hasError = await validate();
    if (!hasError) {
      let codes = await getValues();
      setCredentials({
        loginId: user.id_Login,
        verifyCode: codes,
        valid: !hasError,
      });
    }
  };

  const getValues = async () => {
    let finalcode = "";
    for await (let code of codes) {
      finalcode = finalcode.concat(code.value);
    }
    return finalcode;
  };

  // Méthods
  const validate = async () => {
    let hasError = false;
    for await (let code of codes) {
      if (!code.value) {
        code.setError("*");
        hasError = true;
        return hasError;
      }
    }

    return hasError;
  };

  const handleOk = () => {
    setShowPop(false);
  };

  return (
    <div className={classes.root}>
      {showPop && (
        <Alert
          title="Recuperar Senha"
          handleOk={handleOk}
          message={response.data.message}
        />
      )}

      <Box pb={5}>
        <center className={classes.logo}>
          <AuthLogo />
        </center>

        <Typography variant="body1" pb={5}>
          Um código foi enviado para seu email:
          <span className={classes.email}> {user.login}</span>. Ensira o código
          recebido nos campos abaixo:
        </Typography>
      </Box>

      <Divider className={classes.divider} />

      <Box pt={5} pb={5}>
        <Grid container justify="center" spacing={2}>
          {codes.map((code, index) => (
            <Grid key={index} item>
              <div className={classes.paper}>
                <FTextInput
                  validations={[valitators.isRequiredSimple]}
                  maxlength={1}
                  controller={code}
                ></FTextInput>
              </div>
            </Grid>
          ))}
        </Grid>
      </Box>
      <Divider className={classes.divider} />
      <Loading isLoading={loading} />
      <Grid
        container
        spacing={2}
        justify="flex-end"
        className={classes.buttons}
      >
        <Grid item>
          <FButton disabled={loading} onClick={cancel} type="cancel">
            Cancelar
          </FButton>
        </Grid>
        <Grid item>
          <FButton disabled={loading} onClick={handleValidateCode}>
            Próxima
          </FButton>
        </Grid>
      </Grid>
    </div>
  );
}

export default Code;
