import React, {
  useState,
  createContext,
  useMemo,
  useEffect,
  useLayoutEffect,
  useCallback,
} from 'react';
import repository from '../../data/repository';
import constants from '../../utils/constants';
import { api } from '../../services/api.services';
import { firstBy } from 'thenby';

export const OccurrenceContext = createContext();

export default function OccurrenceProvider(props) {
  const data = useMemo(() => repository(), []);
  // ---------------------------------------------------------------------
  // STATES
  // ---------------------------------------------------------------------
  const initalOccurrences = () =>
    data.getItemParse(constants.OCCURRENCES) === null
      ? []
      : data.getItemParse(constants.OCCURRENCES);

  const [occurrences, setOccurrences] = useState(initalOccurrences);
  const [currentOccurrences, setCurrentOccurrences] = useState(() => []);
  const [setActivePeriod, setActiveOccurrenceTime] = useState(() => {});
  const [reload, setReload] = useState(() => false);
  const [onAddTimer, setOnAddTimer] = useState(() => false);

  const [gameTimes, setGameTimes] = useState(() => []);
  const [isLoading, setLoading] = useState(() => true);
  const [isLoadingOccurrences, setIsLoadingOccurrences] = useState(() => true);

  const [error, setError] = useState(() => false);
  const [play, setPlay] = useState(false);
  const match = data.getItemParse(constants.MATCH);
  const matchId = match?.id;

  const initialClockComment = () =>
    match === null ? undefined : match.clockComment?.startTime;

  const [cronometer, setCronometer] = useState(initialClockComment());
  const [teamSelected, setTeamSelected] = useState(undefined);
  const [selectedPlayer, setSelectedPlayer] = useState(undefined);
  const [selectedPlayers, setSelectedPlayers] = useState([]);
  const [selectedOccurrence, setSelectedOccurrence] = useState(undefined);

  const sanatizeUnit = useCallback((unit) => {
    var inteiro = parseInt(unit);
    if (inteiro < 10) {
      return '0' + inteiro;
    }
    return inteiro;
  }, []);

  const initialCronometerValue = () => {
    let date = data.getItem(constants.TIMER);
    let time = date ?? cronometer;

    // if (time) {
    return time !== undefined
      ? new Date(
          9999,
          1,
          1,
          0,
          sanatizeUnit(time.split(':')[0]),
          sanatizeUnit(time.split(':')[1])
        )
      : time;
    // }

    // return new Date(9999, 1, 1, 0, 0, 0)
  };

  const [cronometerValue, setCronometerValue] = useState(
    initialCronometerValue()
  );
  const [cronometerVisible, setCronometerVisible] = useState(false);

  // ---------------------------------------------------------------------
  // METHODS
  // ---------------------------------------------------------------------

  const calculateCronometer = (value) => {
    // Normal
    if (match.clockComment.clockType.id === 1) return cronometer;

    // Regressivo
    let date = new Date();
    date.setMinutes(match.clockComment.startTime.split(':')[0]);
    date.setSeconds(match.clockComment.startTime.split(':')[1]);

    // subtraindo
    date.setMinutes(date.getMinutes() - value.getMinutes());
    date.setSeconds(date.getSeconds() - value.getSeconds());

    return date;
  };

  const getCalculatedCronometer = (value) => {
    const cronValue = cronometerValue ?? initialCronometerValue();

    // Normal
    let min = sanatizeUnit(cronValue.getMinutes());
    let sec = sanatizeUnit(cronValue.getSeconds());

    if (match.clockComment.clockType.id === 1) {
      return `${min}:${sec}`;
    }

    // Regressivo
    let date = new Date();
    date.setMinutes(match.clockComment.startTime.split(':')[0]);
    date.setSeconds(match.clockComment.startTime.split(':')[1]);

    if (value) {
      min = value.split(':')[0];
      sec = value.split(':')[1];
    }

    // subtraindo
    date.setMinutes(date.getMinutes() - min);
    date.setSeconds(date.getSeconds() - sec);

    min = sanatizeUnit(date.getMinutes());
    sec = sanatizeUnit(date.getSeconds());
    return `${min}:${sec}`;
  };

  const getTimeInfo = (gameTimeId, timeTypeId) => {
    return occurrences.filter((oc) => {
      return (
        (oc.occurrenceType.id === 1 || oc.occurrenceType.id === 2) &&
        oc.gameTime.id === gameTimeId &&
        oc.timeType.id === timeTypeId
      );
    });
  };

  const getGoalsInfo = (gameTimeId, timeTypeId, teamId, playerId) => {
    let occurr = occurrences.filter((oc) => {
      return (
        (oc.gameTime.id === gameTimeId || gameTimeId === undefined) &&
        (oc.occurrenceType.id === 4 ||
          oc.occurrenceType.id === 5 ||
          oc.occurrenceType.id === 6 ||
          oc.occurrenceType.id === 7) &&
        (oc.timeType.id === timeTypeId || timeTypeId === undefined) &&
        (oc.player.id === playerId || playerId === undefined) &&
        (oc.team.id === teamId || teamId === undefined)
      );
    });
    return occurr;
  };

  const golsAgainst = (gameTimeId, timeTypeId, teamId) => {
    let occurr = occurrences.filter((oc) => {
      return (
        oc.occurrenceType.id === 8 &&
        (oc.gameTime.id === gameTimeId || gameTimeId === undefined) &&
        (oc.timeType.id === timeTypeId || timeTypeId === undefined) &&
        (oc.team.id === teamId || teamId === undefined)
      );
    });
    return occurr;
  };

  const configureGameTimes = useCallback(() => {
    let times = [];
    constants.timeType.forEach((timeType) => {
      constants.gameTimeEvents.forEach((element) => {
        let hasGameTime = occurrences.find(
          (x) => x.gameTime.id === element.id && x.timeType.id === timeType.id
        );
        if (hasGameTime) {
          let timeElement = {
            occurrenceId: hasGameTime.id,
            id: element.id,
            name: element.name,
            timeType: hasGameTime.timeType,
          };
          times.push(timeElement);
        }
      });
    });
    setGameTimes(times);
  }, [occurrences]);

  const addOccurrence = (occurrence) => {
    setOccurrences((oldOccur) => [...occurrences, ...occurrence]);
  };

  const editOccurrence = (occurrence) => {
    setOccurrences(
      occurrences.map((item) => (item.id === occurrence.id ? occurrence : item))
    );
  };

  const buildOccurrenceList = (items) => {
    let occurrencyCopy = [...occurrences];
    items.forEach((item) => {
      occurrencyCopy = occurrencyCopy.map((occurr) =>
        occurr.id === item.id ? item : occurr
      );
    });

    setOccurrences(occurrencyCopy);
  };

  const editNewOccurrences = (newOccurrences) => {
    let occurrencesToEdit = [];
    newOccurrences.forEach((occurrence) => {
      let exists = occurrences.find((x) => x.id === occurrence.id);
      if (!exists) {
        addOccurrence(occurrence);
      } else {
        occurrencesToEdit.push(occurrence);
      }
    });

    if (occurrencesToEdit.length > 0) {
      buildOccurrenceList(occurrencesToEdit);
    }
  };

  const deleteOccurrence = async (occurrence, deleteAll) => {
    //if (deleteAll === true) await loadOccurrences(occurrence.matchId, deleteAll);
    let oc = occurrences.filter((item) => item.id !== occurrence.id);
    let currentOc = currentOccurrences.filter(
      (item) => item.id !== occurrence.id
    );
    setCurrentOccurrences((prev) => currentOc);
    setOccurrences((prev) => oc);
  };

  const handlerOnAddTimer = () => {
    setOnAddTimer(true);
  };

  const afterHandlerOnAddTimer = () => {
    setOnAddTimer(false);
  };

  const logout = () => {
    setLoading(true);
    setOccurrences((prev) => []);
    setCurrentOccurrences((prev) => []);
    setActiveOccurrenceTime((prev) => {});
    setSelectedPlayers((prev) => []);
    setSelectedOccurrence((prev) => undefined);
    setPlay((prev) => false);
    setTeamSelected((prev) => undefined);
    setGameTimes((prev) => []);
    setError((prev) => false);
    setCronometerValue((prev) => undefined);
    setLoading((prev) => false);
    setCronometerVisible(false);
    data.clear();
  };

  const applyFilter = useCallback((occurrences, setActivePeriod) => {
    let occ = occurrences.filter((oc) => {
      return (
        oc.gameTime.id === setActivePeriod.gameTime.id &&
        oc.timeType.id === setActivePeriod.timeType.id
      );
    });

    occ.sort(
      firstBy(function (v1, v2) {
        var time1 = parseFloat(
          v1.occurrenceTime.replace(':', '').replace(/[^\d.-]/g, '')
        );
        var time2 = parseFloat(
          v2.occurrenceTime.replace(':', '').replace(/[^\d.-]/g, '')
        );
        if (time1 > time2) return -1;
        if (time1 < time2) return 1;
        return 0;
      })
    );
    setCurrentOccurrences((prev) => occ);
  }, []);

  const matchIsEnded = () => {
    let finalizada = occurrences.find((x) => x.occurrenceType.id === 3);
    return finalizada !== undefined;
  };

  // ---------------------------------------------------------------------
  // EFFECTS
  // ---------------------------------------------------------------------

  useEffect(() => {
    async function load() {
      try {
        setIsLoadingOccurrences(true);
        var resp = await api.get(`occurrence/${matchId}`);
        if (resp && resp.status === 200) {
          setOccurrences((prev) => resp.data);
        }
      } catch (error) {
        setError(error);
      } finally {
        setIsLoadingOccurrences(false);
        setReload(false);
      }
    }

    if (reload === true) load();
  }, [matchId, occurrences.length, reload]);

  useLayoutEffect(() => {
    data.setItemStringify(constants.OCCURRENCES, occurrences);
    configureGameTimes();
  }, [occurrences, configureGameTimes, data]);

  useEffect(() => {
    if (occurrences.length > 0 && setActivePeriod)
      applyFilter(occurrences, setActivePeriod);
  }, [occurrences, setActivePeriod, applyFilter]);

  // ---------------------------------------------------------------------
  // EFFECTS
  // ---------------------------------------------------------------------

  return (
    <OccurrenceContext.Provider
      value={{
        applyFilter,
        occurrences,
        currentOccurrences,
        setOccurrences,
        selectedOccurrence,
        setSelectedOccurrence,
        setLoading,
        matchIsEnded,
        isLoading,
        activeOccurrenceTime: setActivePeriod,
        setActiveOccurrenceTime,
        setReload,

        isLoadingOccurrences,
        setIsLoadingOccurrences,

        addOccurrence,
        editOccurrence,
        deleteOccurrence,
        editNewOccurrences,
        gameTimes,
        getTimeInfo,
        getGoalsInfo,
        golsAgainst,

        // Cronometer
        play,
        setPlay,
        cronometer,
        setCronometer,
        cronometerValue,
        sanatizeUnit,
        initialCronometerValue,
        setCronometerValue,
        calculateCronometer,
        getCalculatedCronometer,
        cronometerVisible,
        setCronometerVisible,

        selectedPlayer,
        selectedPlayers,
        teamSelected,

        setSelectedPlayer,
        setSelectedPlayers,
        setTeamSelected,

        logout,
        handlerOnAddTimer,
        afterHandlerOnAddTimer,
        onAddTimer,
        // ERRORS
        setError,
        error,
      }}
    >
      {props.children}
    </OccurrenceContext.Provider>
  );
}
