import { useState, useContext, useMemo } from "react";
import { MatchContext } from "../../../../../components/contexts/match.provider";
import { OccurrenceContext } from "../../../../../components/contexts/occurrences.provider";
import repository from "../../../../../data/repository";
import constants from "../../../../../utils/constants";

export default function useCronometerHook(clockComment) {
    const mainContext = useContext(MatchContext);
    const occurrenceContext = useContext(OccurrenceContext);
    const data = useMemo(() => repository(), []);

    const initial = () => {
        if (clockComment === undefined || clockComment.clockType === undefined) {
            let cm = {
                comment: "",
                startTime: 0,
                clockType: constants.cronometer.find((x) => x.id === 1),
            };

            return cm;
        }

        return clockComment;
    };
    const [clock, setClock] = useState(initial());

    const updateClockComment = (clockComment) => {
        setClock(prev => clockComment);

        mainContext.updateRefereeAndClock(clockComment);
        mainContext.updateMatch();

        occurrenceContext.setPlay(false);
        occurrenceContext.setCronometer(clockComment.startTime);

        
        let date = new Date();
        date.setMinutes(clockComment.startTime.split(':')[0]);
        date.setSeconds(clockComment.startTime.split(':')[1]);
        
        let timer = `${occurrenceContext.sanatizeUnit(date.getMinutes())}:${occurrenceContext.sanatizeUnit(date.getSeconds())}`;
        
        data.setItem(constants.TIMER, timer);
        occurrenceContext.setCronometerValue(date);
    };

    const getClockTime = () => {
        if (!clock) return "--:--";
        let clockTime =
            clock && clock.startTime && clock.startTime === "0"
                ? "--:--"
                : clock.startTime;
        return clockTime;
    };

    const getClockType = () => {
        if (!clock) return null;
        let clockType =
            clock && clock.clockType ? clock.clockType : constants.cronometer[0];
        return clockType;
    };

    return {
        clock,
        clockType: getClockType(),
        startTime: getClockTime(),

        // ----------------------------------------------------------
        // Altera o Tipo de Cronometro
        // ----------------------------------------------------------
        setClockType: (clockType) => {
            clock.clockType = clockType;
            if (clockType.id === 1) {
                clock.startTime = "00:00";
            }
            updateClockComment(clock);
        },

        // ----------------------------------------------------------
        // Altera o Tipo de Cronometro
        // ----------------------------------------------------------
        setInit: (value) => {
            clock.startTime = value;
            updateClockComment(clock);
        },
    };
}
