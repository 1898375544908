import React from "react";
import TextField from "@material-ui/core/TextField";
import InputMask from "react-input-mask";
import FController from "./FControllerHook";

export default function FTimeInput({
    mainContext,
    value,
    label,
    controller,
    onChange,
    onBlur,
    className,
    inputProps,
    margin = "none",
}) {


    if (!controller) {
        controller = FController();
    }
    // Inicia o controller com as configurações default

    controller.init(controller, value);

    return (
        <div>
            <InputMask
                disabled={mainContext ? mainContext.isMatchDone : false}
                className={className}
                mask="99:99"
                value={controller.value}
                onBlur={(e) => {
                    if (onBlur) onBlur(e.target.value);
                }}
                onChange={(e) => {
                    controller.value = e.target.value;
                    if (onChange) onChange(e.target.value);
                }}

            >
                <TextField
                    value={controller.value}
                    error={controller.error}
                    className={className}
                    helperText={controller.message}
                    variant="outlined"
                    inputProps={inputProps}
                    label={label}
                    margin={margin}
                />
            </InputMask>
        </div>
    );
}
