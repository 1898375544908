import { useState, useContext, useMemo, useCallback } from "react";
import repository from "../../../../../data/repository";
import constants from "../../../../../utils/constants";

import { MatchContext } from "../../../../../components/contexts/match.provider";

export default function useRefereeHook() {
  const mainContext = useContext(MatchContext);
  const initial = useCallback(() => {
    return mainContext.match.referees;
  }, [mainContext.match]);

  const [referees, setReferees] = useState(initial());

  const data = useMemo(() => repository(), []);

  const updateReferres = async (referee) => {
    setReferees(
      referees.map((item) => (item.index === referee.index ? referee : item))
    );
    mainContext.updateReferee(referee);
    mainContext.updateMatch();
  };

  const createReferee = (id, value) => {
    let founded = referees.find((item) => item.refereeFunction.id === id);
    if (founded) {
      founded.index = id;
      founded.name = value;
      founded.haschanged = true;
      updateReferres(founded);
    } else {
      let referee = {
        index: id,
        name: value,
        haschanged: true,
        refereeFunction: constants.refereesFunctions[id - 1],
      };
      setReferees([...referees, referee]);
      mainContext.addReferee(referee);
      mainContext.updateMatch();
    }
  };

  const findOriginal = (referee) => {
    let originalsReferees = data.getItemParse(constants.REFEREES);
    if (originalsReferees && originalsReferees.length > 0) {
      return originalsReferees.find((item) => item.index === referee.index);
    }
    return undefined;
  };

  const resetChanged = (referee) => {
    referee.name = "";
    referee.haschanged = false;
    let original = findOriginal(referee);
    if (original) {
      referee.haschanged = false;
      referee.name = original.name;
      updateReferres(referee);
    } else {
      // Removo da lista original
      let items = mainContext.match.referees.filter((x) => {
        return x.index !== referee.index;
      });
      mainContext.match.referees = items;

      // Removo da lista de atualizados
      let updatedItems = mainContext.updatedReferees.filter((x) => {
        return x.index !== referee.index;
      });
      mainContext.updatedReferees = updatedItems;
      mainContext.updateMatch();
      setReferees([...referees]);
    }
  };

  const getAnotator = () => {
    return mainContext.user;
  };

  return {
    referees: referees,
    getAnotator: () => getAnotator(),

    // ----------------------------------------------------------
    // Altera a função do membro da equipe técnica
    // ----------------------------------------------------------
    changeFunction: (referee, value) => {
      referee.refereeFunction = value;
      updateReferres(referee);
    },

    // ----------------------------------------------------------
    // Altera o nome e atribui como 'alterado'
    // ----------------------------------------------------------
    changeName: (value, id) => {
      createReferee(id, value);
    },

    // ----------------------------------------------------------
    // Volta para o nome que veio do Admin
    // ----------------------------------------------------------
    reset: (referee) => {
      resetChanged(referee);
    },
  };
}
