import { useState, useEffect, useCallback, useMemo } from 'react';
import repository from '../../data/repository';
import constants from '../../utils/constants';

// My Components
import FController from '../inputs/FControllerHook';

export default function useClockHook(occurrenceContext, allWaysVisible) {
  const data = useMemo(() => repository(), []);
  const match = data.getItemParse(constants.MATCH);

  // initial Value
  const initValue = useCallback(() => {
    occurrenceContext.initialCronometerValue();
  }, [occurrenceContext]);
  const [myValue, setMyValue] = useState(initValue());
  const timerController = FController();
  const [cronometerVisible, setCronometerVisible] = useState(allWaysVisible);

  const setCurrentTime = useCallback(() => {
    let date = data.getItem(constants.TIMER);
    let time = date ?? match.clockComment?.startTime;

    setMyValue((prev) => time);
  }, [data, match.clockComment.startTime]);

  const updateValue = useCallback(
    (newValue) => {
      let validMinute =
        /^([0-5]?[0-9]|5[0-9]):([0-5][0-9])(:[0-5][0-9])?$/.test(newValue);

      if (!validMinute) {
        setCurrentTime();
        setMyValue((prev) => myValue);
        timerController.setValue((prev) => myValue);
        return;
      }

      if (newValue === '00:00' || newValue === '59:59') {
        occurrenceContext.setPlay(false);
      }

      if (validMinute) {
        setMyValue(newValue);
        data.setItem(constants.TIMER, newValue);
        timerController.setValue((prev) => newValue);
        let date = new Date();
        date.setMinutes(newValue.split(':')[0]);
        date.setSeconds(newValue.split(':')[1]);

        occurrenceContext.setCronometerValue(date);
        occurrenceContext.calculateCronometer(date);
      }
    },
    [setCurrentTime, timerController, myValue, data, occurrenceContext]
  );

  const updateStatus = (value, targetValue) => {
    if (value === true && targetValue) {
      updateValue(targetValue);
    }

    occurrenceContext.setPlay(value);
    setCronometerVisible(value);
  };

  useEffect(() => {
    setCurrentTime();
  }, [setCurrentTime]);

  return {
    myValue: myValue,
    timerController: timerController,
    play: occurrenceContext.play,
    setPlay: occurrenceContext.setPlay,
    cronometerVisible: cronometerVisible,
    clockType: match.clockComment.clockType,

    updateValue: updateValue,
    updateStatus: updateStatus,
  };
}
