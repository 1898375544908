import React, { useEffect, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
import UpdateIcon from '@material-ui/icons/Update';
import Tooltip from '@material-ui/core/Tooltip';
import clsx from 'clsx';
import repository from '../../data/repository';
import constants from '../../utils/constants';

// My components
import Enter from '../../theme/animations/enter.animations';
import FTimeInput from '../inputs/FTimeInput';
import useClockHook from './clock.hook';
import useOccurrencePeriodsHook from '../../pages/match/occurrences/components/hooks/occurrencePeriods.hooks';

const useStyles = makeStyles((theme) => ({
  root: {},
  grid: {
    width: 110,
  },
  timerIcon: {
    marginTop: 7,
    marginRight: 12,
  },
  invert: { transform: 'rotate(180deg) scaleY(-1) !important' },
  occurrenceTime: {
    '& .MuiOutlinedInput-root': {
      height: 35,
      '& fieldset': {
        borderColor: theme.palette.action.cancel,
        borderWidth: 0,
      },
      '&:hover fieldset': {
        borderColor: theme.palette.action.cancel,
        borderWidth: 1,
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.action.main,
      },
    },
    '& .MuiInputBase-input': {
      fontSize: 35,
      padding: 0,
      fontWeight: 500,
      cursor: 'text',
      color: theme.palette.action.cancel,
      textAlign: 'center',
    },
  },
  custom: {
    '& .MuiInputBase-input': {
      color: theme.palette.action.cancel,
    },
  },
  customButtom: {
    color: 'white !important',
  },
  left: {
    paddingLeft: '15px !important',
  },
}));

export default function Clock({
  occurrenceContext,
  allWaysVisible = false,
  mainContext,
  showAction = true,
}) {
  const classes = useStyles();
  const data = useMemo(() => repository(), []);
  let date = data.getItem(constants.TIMER);

  const occurrencePeriodsHook = useOccurrencePeriodsHook(mainContext.match);
  const useclockHook = useClockHook(occurrenceContext, allWaysVisible);

  useEffect(() => {
    if (occurrenceContext.onAddTimer) {
      useclockHook.updateValue(
        occurrencePeriodsHook.match.clockComment.startTime
      );
    }
  }, [
    occurrenceContext.onAddTimer,
    occurrencePeriodsHook.match.clockComment.startTime,
    useclockHook,
  ]);

  return (
    <Grid
      className={clsx(classes.root, { [classes.custom]: allWaysVisible })}
      container
      spacing={1}
      alignItems="center"
      justify="center"
    >
      <Grid item className={classes.timerIcon}>
        {useclockHook.clockType.id === 1 && (
          <Tooltip title="Normal ou Progressivo">
            <UpdateIcon />
          </Tooltip>
        )}

        {useclockHook.clockType.id === 2 && (
          <Tooltip title="Regressivo">
            <UpdateIcon className={classes.invert} />
          </Tooltip>
        )}
      </Grid>
      <Grid item className={classes.grid}>
        <FTimeInput
          controller={useclockHook.timerController}
          className={classes.occurrenceTime}
          value={date}
          onChange={(value) => {
            useclockHook.updateValue(value);
          }}
        ></FTimeInput>
      </Grid>

      {showAction && (
        <Grid item>
          <Enter>
            {useclockHook.play === true && (
              <Tooltip title="Pausa o cronômetro">
                <IconButton
                  className={clsx({ [classes.customButtom]: allWaysVisible })}
                  onClick={() => useclockHook.updateStatus(false)}
                >
                  <PauseIcon />
                </IconButton>
              </Tooltip>
            )}

            {useclockHook.play === false && (
              <Tooltip title="Continua o cronômetro">
                <IconButton
                  className={clsx({ [classes.customButtom]: allWaysVisible })}
                  onClick={() => useclockHook.updateStatus(true)}
                >
                  <PlayArrowIcon />
                </IconButton>
              </Tooltip>
            )}
          </Enter>
        </Grid>
      )}
    </Grid>
  );
}
