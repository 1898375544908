import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Radio from '@material-ui/core/Radio';
import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Search from '@material-ui/icons/Search';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

// My custom components
import constants from '../../../../../utils/constants';
import repository from '../../../../../data/repository';
import functions from '../../../../../utils/functions';
import FRadio from '../../../../../components/radio/FRadio';
import FButton from '../../../../../components/button/FButton';
import ButtonDocument from '../../../../../components/button/ButtonDocuments';
import PopAddPlayer from '../../pops/addplayers';
import usePlayerHook from '../hooks/players.hook';
import useSearchPlayersHook from '../hooks/search.hook';
import Fade from '../../../../../theme/animations/fade.animations';
import EnhancedTableHead from '../table/head';

const useStyles = makeStyles((theme) => ({
  table: {
    width: '100%',
    minWidth: 550,
    overflow: 'hidden',
  },
  nickname: {
    fontSize: 15,
    textTransform: 'lowercase',
    '&:first-letter': {
      textTransform: 'capitalize',
    },
  },
  nicknameOnName: {
    fontSize: 15,
    textTransform: 'lowercase',
    '&:first-letter': {
      textTransform: 'capitalize',
    },
    display: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'block',
    },
  },
  nicknamecell: {
    paddingRight: 25,
    maxWidth: 120,
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  docscell: {
    paddingRight: 25,
    maxWidth: 120,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  namecell: {
    paddingRight: 15,
    paddingTop: 10,
    paddingBottom: 10,
    wordBreak: 'break-word',
  },
  defaultNumber: {
    textAlign: 'center',
    '& *': {
      align: 'center',
      width: 55,
      textAlign: 'center',
    },
  },
  number: {
    textAlign: 'center',
  },
  position: {
    padding: 5,
    paddingRight: 20,
    paddingLeft: 10,
    minWidth: 170,
    '@supports ( -moz-appearance:none )': {
      paddingTop: 11,
    },
  },
  nascRG: {
    fontSize: 12,
  },
  radio: {
    maxWidth: 52,
    paddingLeft: 5,
    paddingRight: 5,
  },
  radioscell: {
    textAlign: 'left',
    paddingLeft: 8,
  },
  noLeftPadding: {
    paddingLeft: 10,
  },
  gridSpacer: {
    paddingTop: theme.spacing(5),
  },
  row: {},
  search: {
    color: 'lightgrey',
  },
  newMember: {
    maxWidth: 350,
  },
}));

export default function PlayersTable({ team, mainContext }) {
  // Styles
  const classes = useStyles();

  // States
  const playersHook = usePlayerHook(team);
  const [players, setPlayers] = useState(() => team.players);
  const [openAddPlayer, setOpenAddPlayer] = useState(false);
  const useSearch = useSearchPlayersHook();
  const repositoryData = repository();

  useEffect(() => {
    //--------------------------------------------------
    // Sem esse useEffect, ao adicionar um segundo player
    // a lista, ela NÃO É ATUALIZADA. ???????????
    //--------------------------------------------------
  }, [players]);

  //--------------------------------------------------
  // FILTRO
  //--------------------------------------------------
  const handleRequestSort = async (event, property) => {
    await mainContext.resetTeamPlayersOrder(playersHook.players);
    const isAsc =
      playersHook.orderBy === property && playersHook.order === 'asc';
    playersHook.setOrder(isAsc);
    playersHook.setOrderBy(property);
  };

  const descendingComparator = (a, b, orderBy) => {
    // ordenação por nome ou apelido
    if (orderBy === 'name' || orderBy === 'nickName') {
      return comparatorNormal(a, b, orderBy);
    }

    // ordenação pelo número da camisa
    if (orderBy === 'defaultNumber') {
      return comparatorWithNumber(a, b, orderBy);
    }

    // ordenação pela posição
    if (orderBy === 'position') {
      return comparatorPosition(a, b, orderBy);
    }

    // ordenação pela escalação
    return comparatorScalation(a, b, orderBy);
  };

  const comparatorNormal = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
  };

  const comparatorPosition = (a, b, orderBy) => {
    if (b.filter[orderBy].name < a.filter[orderBy].name) {
      return -1;
    }
    if (b.filter[orderBy].name > a.filter[orderBy].name) {
      return 1;
    }
  };

  const comparatorScalation = (a, b, orderBy) => {
    if (b.filter[orderBy] < a.filter[orderBy]) {
      return -1;
    }
    if (b.filter[orderBy] > a.filter[orderBy]) {
      return 1;
    }
  };

  const comparatorWithNumber = (a, b, orderBy) => {
    let ord = playersHook.order === 'asc' ? 999 : 0;
    let right =
      b.filter[orderBy] === undefined ? ord : parseInt(b.filter[orderBy]);
    let left =
      a.filter[orderBy] === undefined ? ord : parseInt(a.filter[orderBy]);

    if (right < left) {
      return -1;
    }
    if (right > left) {
      return 1;
    }
    return 0;
  };

  const getComparator = () => {
    return playersHook.order === 'desc'
      ? (a, b) => descendingComparator(a, b, playersHook.orderBy)
      : (a, b) => -descendingComparator(a, b, playersHook.orderBy);
  };

  const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };
  //--------------------------------------------------
  // FILTRO
  //--------------------------------------------------

  //Methods;

  const openAddPlayerHandler = () => {
    setOpenAddPlayer(true);
  };

  const closeAddHandler = () => {
    setOpenAddPlayer(false);
  };

  const handleSearch = (event) => {
    const result = useSearch.search(event.target.value, team.players);
    setPlayers(result);
  };

  const update = () => {
    mainContext.sortPlayers(team);
    mainContext.updateMatch();
  };

  const getPosition = (teamModalityId, position) => {
    return constants.positions
      .find((modality) => modality.modalityId === teamModalityId)
      .positions.find((pos) => pos.id === position.id);
  };

  const getDefaultPlayerNumber = (value) => {
    if (value) return value;
    return '';
  };

  const handleDocAction = async (playerId, status) => {
    const updatedMatch = mainContext.match;
    if (status === 'OK') {
      const listPlayers = team.players.map((p) =>
        p.id === playerId
          ? {
              ...p,
              docsVerified: {
                name: 'OK',
                id: 1,
              },
            }
          : p
      );

      if (mainContext.step === 0) {
        updatedMatch.home.players = listPlayers;
        repositoryData.setItemStringify(constants.MATCH, updatedMatch);
      } else {
        updatedMatch.away.players = listPlayers;
        repositoryData.setItemStringify(constants.MATCH, updatedMatch);
      }

      setPlayers(listPlayers);
    } else {
      const listPlayers = team.players.map((p) =>
        p.id === playerId
          ? {
              ...p,
              docsVerified: {
                name: 'Pendente',
                id: 2,
              },
            }
          : p
      );

      if (mainContext.step === 0) {
        updatedMatch.home.players = listPlayers;
        repositoryData.setItemStringify(constants.MATCH, updatedMatch);
      } else {
        updatedMatch.away.players = listPlayers;
        repositoryData.setItemStringify(constants.MATCH, updatedMatch);
      }
      setPlayers(listPlayers);
    }
  };

  return (
    <div>
      {/* Pop para adição de Jogador */}
      {openAddPlayer && (
        <PopAddPlayer
          team={team}
          match={mainContext.match}
          updateCaller={update}
          open={openAddPlayer}
          step={mainContext.step}
          closeHandler={closeAddHandler}
          addType={constants.AddType.PLAYER}
        ></PopAddPlayer>
      )}
      <Fade>
        <Grid container direction="column">
          <Grid
            item
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <Grid item lg={4} md={5}>
              <TextField
                variant="outlined"
                margin="dense"
                fullWidth
                placeholder="Pesquisar"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <Search className={classes.search} />
                    </InputAdornment>
                  ),
                }}
                onChange={handleSearch}
              />
            </Grid>
            <Grid
              item
              xs={4}
              sm={5}
              container
              justifyContent="flex-end"
              className={classes.newMember}
            >
              <FButton
                thin
                fullWidth
                type="primary"
                onClick={openAddPlayerHandler}
                disabled={mainContext.isMatchDone}
              >
                Inscrever atleta no time
              </FButton>
            </Grid>
          </Grid>
          <Grid item container className={classes.gridSpacer}>
            <TableContainer>
              <Table className={classes.table} aria-label="Scalation Table">
                <EnhancedTableHead
                  classes={classes}
                  order={playersHook.order}
                  orderBy={playersHook.orderBy}
                  onRequestSort={handleRequestSort}
                  headCells={constants.playerHeadCells}
                />
                <TableBody>
                  {stableSort(players, getComparator()).map((player) => {
                    return (
                      <TableRow key={player.id} className={classes.row}>
                        <TableCell className={classes.nicknamecell}>
                          <Typography variant="h4" className={classes.nickname}>
                            {functions.setNicknameWith10(player)}
                          </Typography>
                        </TableCell>

                        <TableCell className={classes.docscell}>
                          <ButtonDocument
                            documentId={player.id}
                            isVeryfiDoc={player.docsVerified?.name === 'OK'}
                            handleAction={(status) =>
                              handleDocAction(player.id, status)
                            }
                          />
                        </TableCell>

                        <TableCell className={classes.namecell}>
                          <Typography
                            variant="h4"
                            className={classes.nicknameOnName}
                          >
                            {functions.setNicknameWith10(player)}
                          </Typography>
                          {player?.name}
                          <Typography
                            variant="body2"
                            className={classes.nascRG}
                          >
                            <strong>Nasc: </strong>
                            {functions.formatDate(player.birthDate)}
                            {' - '}
                            <strong>Rg: </strong> {player.document}
                            {' - '}
                            <strong>Insc: </strong>
                            {functions.formatDate(player.inscriptionDate)}
                          </Typography>
                        </TableCell>

                        <TableCell className={classes.defaultNumber}>
                          <TextField
                            disabled={mainContext.isMatchDone}
                            value={getDefaultPlayerNumber(player.defaultNumber)}
                            variant="outlined"
                            width="65px !important"
                            inputProps={{
                              maxLength: 3,
                            }}
                            onChange={(event) => {
                              playersHook.changeNumber(
                                event.target.value,
                                player
                              );
                            }}
                          />
                        </TableCell>

                        <TableCell className={classes.position}>
                          <Select
                            disabled={mainContext.isMatchDone}
                            variant="outlined"
                            fullWidth
                            value={getPosition(
                              team.modality.id,
                              player.position
                            )}
                            onChange={(e) => {
                              playersHook.changePosition(
                                getPosition(team.modality.id, e.target.value),
                                player
                              );
                            }}
                          >
                            {/* TODO {constants.positions.filter((x)=> x.Modality === team.modality.name).map((item) => ( */}
                            {constants.positions
                              .find((x) => x.modalityId === team.modality.id)
                              .positions.map((item) => (
                                <MenuItem key={item.id} value={item}>
                                  {item?.name}
                                </MenuItem>
                              ))}
                          </Select>
                        </TableCell>

                        <TableCell
                          align="center"
                          className={classes.radioscell}
                        >
                          <FRadio
                            disabled={mainContext.isMatchDone}
                            onClick={(event) => {
                              playersHook.setUnscaled(
                                player,
                                !player.isStarter
                              );
                            }}
                            onChange={() => {
                              playersHook.setStarter(player, true);
                            }}
                            checked={player.isStarter === true}
                          />
                        </TableCell>

                        <TableCell
                          align="center"
                          className={classes.radioscell}
                        >
                          <FRadio
                            disabled={mainContext.isMatchDone}
                            onClick={() => {
                              playersHook.setUnscaled(player, player.isStarter);
                            }}
                            onChange={() => {
                              playersHook.setStarter(player, false);
                            }}
                            checked={player.isReserve === true}
                          />
                        </TableCell>

                        <TableCell
                          align="center"
                          className={classes.radioscell}
                        >
                          <Radio
                            disabled={
                              playersHook.isUnscaled(player) ||
                              mainContext.isMatchDone
                            }
                            onChange={(event) => {
                              playersHook.setCaptain(player);
                            }}
                            checked={player.isCaptain}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Fade>
    </div>
  );
}
