import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";

import constants from "../../utils/constants";

// My Components
import FButton from "../button/FButton";

function ErrorAlert({
    title = "Futstat",
    open,
    message,
    onClose,
    response,
    size = "xs",
}) {
    if (message === undefined) message = constants.GENERICERROR;
    const notifications =
        response && response.data ? response.data.notifications : undefined;
    return (
        <div>
            <Dialog open={open} maxWidth={size} fullWidth={true}>
                <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
                <DialogContent>
                    {notifications && (
                        <DialogContentText id="alert-dialog-description">
                            {notifications[0].message}
                        </DialogContentText>
                    )}

                    {!notifications && (
                        <DialogContentText id="alert-dialog-description">
                            {message}
                        </DialogContentText>
                    )}
                </DialogContent>
                <DialogActions>
                    <FButton thin autoFocus onClick={() => onClose(false)} color="secondary">
                        OK
                    </FButton>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default ErrorAlert;
