import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';

// My components
import FButton from '../../../../../components/button/FButton';
import PopGol from '../../pops/gol';
import PopSinglePlayer from '../../pops/singlePlayer';
import PopMultiPlayer from '../../pops/multiPlayer';
import PopSingleTeam from '../../pops/singleTeam';

import functions from '../../../../../utils/functions';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    paddingTop: 3,
    '& .MuiButton-label': {
      lineHeight: 1.3,
    },
    backgroundColor: 'ligthgrey',
    display: 'block',
  },
  item: {
    width: 145,
  },
  divider: {
    margin: '5px 0',
  },
}));

export default function Actions({
  occurrenceHook,
  occurrenceContext,
  mainContext,
}) {
  const classes = useStyles();
  const selectedPlayers = occurrenceHook.selectedPlayers.length;

  const [event, setEvent] = useState(undefined);

  useEffect(() => {
    if (event) event();
  }, [event]);

  const openToNewEvent = (event) => {
    setEvent((prev) => event);
    occurrenceHook.clearSelectedOccurrence();
  };

  const openPopSingle = (type, showReserves, showTechnical, canMultiSelect) => {
    //occurrenceHook.setTeamSelected(prev => undefined);

    let parameters = {
      type: functions.getOccurrenceType(type),
      showReserves: showReserves,
      showTechnical: showTechnical,
      canSelect: canMultiSelect,
    };

    if (selectedPlayers > 0) {
      if (parameters.type.id === 26) {
        occurrenceHook.handleMVP(
          occurrenceHook.selectedPlayers[0].id,
          occurrenceHook.match.id
        );
        return;
      }

      executeAction(parameters.type);
      return;
    }

    occurrenceHook.setParameters(parameters);
    occurrenceHook.setOpenSingle(true);
  };

  const openPopMulti = (type, showReserves, showTechnical, canMultiSelect) => {
    let parameters = {
      type: functions.getOccurrenceType(type),
      showReserves: showReserves,
      showTechnical: showTechnical,
      canMultiSelect: canMultiSelect,
    };

    if (selectedPlayers > 0) {
      executeAction(parameters.type);
      return;
    }

    occurrenceHook.setParameters(parameters);
    occurrenceHook.setOpenMulti(true);
  };

  const openGol = (type) => {
    let parameters = { type: functions.getOccurrenceType(type) };
    if (selectedPlayers > 0) {
      executeAction(parameters.type);
      return;
    }

    occurrenceHook.setParameters(parameters);
    occurrenceHook.setGolIsOpen(true);
  };

  const executeAction = (type) => {
    occurrenceHook.dispatch({
      type: 'addOccurrence',
      payload: { occurrenceType: type },
    });
  };

  const executeActionPop = (occurrence) => {
    console.log('aaaaa', occurrence);

    let actionType = occurrence?.id ? 'editOccurrence' : 'addOccurrence';
    occurrenceHook.dispatch({ type: actionType, payload: occurrence });
  };

  const cancel = () => {
    occurrenceHook.setGolIsOpen(false);
    occurrenceHook.setOpenSingle(false);
    occurrenceHook.setOpenMulti(false);
    occurrenceHook.setOpenSingleTeam(false);
    occurrenceHook.clearSelectedPlayer();
    setEvent((prev) => undefined);
  };

  const cannotExecute = () => {
    if (occurrenceHook.isLoadingOccurrences) return true;
    let player = occurrenceHook.selectedPlayers[0];
    if (player && player.technicalFunction) return true;
    return false;
  };

  return (
    <div className={classes.root}>
      {/* Botões */}
      <Grid container direction="column" spacing={2}>
        <Grid item container justify="center" spacing={2}>
          <Grid item className={classes.item}>
            <FButton
              thin
              disabled={cannotExecute() || mainContext.isMatchDone}
              fullWidth
              type="cancel"
              onClick={() => openToNewEvent(() => openGol(4))}
            >
              Gol
            </FButton>
          </Grid>
          <Grid item className={classes.item}>
            <FButton
              thin
              disabled={cannotExecute() || mainContext.isMatchDone}
              fullWidth
              type="cancel"
              onClick={() => openToNewEvent(() => openPopSingle(5))}
            >
              Gol de falta
            </FButton>
          </Grid>
          <Grid item className={classes.item}>
            <FButton
              thin
              disabled={cannotExecute() || mainContext.isMatchDone}
              fullWidth
              type="cancel"
              onClick={() => openToNewEvent(() => openPopSingle(6))}
            >
              Gol de tiro livre
            </FButton>
          </Grid>
          <Grid item className={classes.item}>
            <FButton
              thin
              disabled={cannotExecute() || mainContext.isMatchDone}
              fullWidth
              type="cancel"
              onClick={() => openToNewEvent(() => openPopSingle(15))}
            >
              Falta
            </FButton>
          </Grid>
          <Grid item className={classes.item}>
            <FButton
              thin
              disabled={
                occurrenceHook.isLoadingOccurrences || mainContext.isMatchDone
              }
              fullWidth
              type="warning"
              onClick={() => openToNewEvent(() => openPopMulti(9, true, true))}
            >
              Cartão amarelo
            </FButton>
          </Grid>
          <Grid item className={classes.item}>
            <FButton
              thin
              disabled={
                occurrenceHook.isLoadingOccurrences || mainContext.isMatchDone
              }
              fullWidth
              type="danger"
              onClick={() => openToNewEvent(() => openPopMulti(10, true, true))}
            >
              Cartão vermelho
            </FButton>
          </Grid>
        </Grid>

        <Grid item container justify="center" spacing={2}>
          <Grid item className={classes.item}>
            <FButton
              thin
              disabled={cannotExecute() || mainContext.isMatchDone}
              fullWidth
              type="cancel"
              onClick={() => openToNewEvent(() => openPopSingle(7))}
            >
              Gol de Pênalti
            </FButton>
          </Grid>
          <Grid item className={classes.item}>
            <FButton
              thin
              disabled={cannotExecute() || mainContext.isMatchDone}
              fullWidth
              type="cancel"
              onClick={() => openToNewEvent(() => openPopSingle(8))}
            >
              Gol contra
            </FButton>
          </Grid>
          <Grid item className={classes.item}>
            <FButton
              thin
              disabled={cannotExecute() || mainContext.isMatchDone}
              fullWidth
              type="cancel"
              onClick={() => openToNewEvent(() => openPopSingle(16))}
            >
              Pênalti defendido
            </FButton>
          </Grid>
          <Grid item className={classes.item}>
            <FButton
              thin
              disabled={cannotExecute() || mainContext.isMatchDone}
              fullWidth
              type="cancel"
              onClick={() => openToNewEvent(() => openPopSingle(17))}
            >
              Tiro livre defendido
            </FButton>
          </Grid>
          {/* <Grid item className={classes.item}>
                        <FButton thin disabled={cannotExecute() || mainContext.isMatchDone} fullWidth type="cancel" onClick={() => openToNewEvent(() => openPopSingle(18))}>Obstrução</FButton>
                    </Grid>
                    <Grid item className={classes.item}>
                        <FButton thin disabled={cannotExecute() || mainContext.isMatchDone} fullWidth type="cancel" onClick={() => openToNewEvent(() => openPopSingle(19))}>Jogo perigoso</FButton>
                    </Grid> */}
          <Grid item className={classes.item}>
            <FButton
              thin
              disabled={cannotExecute() || mainContext.isMatchDone}
              fullWidth
              type="cancel"
              onClick={() => openToNewEvent(() => openPopSingle(12))}
            >
              Falta + CA
            </FButton>
          </Grid>
          <Grid item className={classes.item}>
            <FButton
              thin
              disabled={cannotExecute() || mainContext.isMatchDone}
              fullWidth
              type="cancel"
              onClick={() => openToNewEvent(() => openPopSingle(13))}
            >
              Falta + CV
            </FButton>
          </Grid>
          <Grid item className={classes.item}>
            <FButton
              thin
              disabled={cannotExecute() || mainContext.isMatchDone}
              fullWidth
              type="cancel"
              onClick={() => openToNewEvent(() => openPopSingle(14))}
            >
              CA + CV
            </FButton>
          </Grid>
          <Grid item className={classes.item}>
            <FButton
              thin
              disabled={
                occurrenceHook.isLoadingOccurrences || mainContext.isMatchDone
              }
              fullWidth
              type="cancel"
              onClick={() => openToNewEvent(() => openPopSingle(11))}
            >
              Cartão Azul
            </FButton>
          </Grid>

          <Grid item className={classes.item}>
            <FButton
              thin
              disabled={
                occurrenceHook.isLoadingOccurrences ||
                !occurrenceContext.matchIsEnded()
              }
              fullWidth
              type="primary"
              onClick={() => openToNewEvent(() => openPopSingle(26))}
            >
              MVP
            </FButton>
          </Grid>
        </Grid>

        <Grid item>
          <Divider className={classes.divider}></Divider>
        </Grid>
      </Grid>

      {/* Pops */}
      {occurrenceHook.golIsOpen && (
        <PopGol
          parameters={occurrenceHook.parameters}
          occurrenceHook={occurrenceHook}
          open={occurrenceHook.golIsOpen}
          executeActionPop={executeActionPop}
          handleCancel={cancel}
        ></PopGol>
      )}

      {occurrenceHook.openSingle && (
        <PopSinglePlayer
          parameters={occurrenceHook.parameters}
          occurrenceHook={occurrenceHook}
          open={occurrenceHook.openSingle}
          executeActionPop={executeActionPop}
          handleCancel={cancel}
        ></PopSinglePlayer>
      )}

      {occurrenceHook.openMulti && (
        <PopMultiPlayer
          parameters={occurrenceHook.parameters}
          occurrenceHook={occurrenceHook}
          open={occurrenceHook.openMulti}
          executeActionPop={executeActionPop}
          handleCancel={cancel}
        ></PopMultiPlayer>
      )}

      {occurrenceHook.openSingleTeam && (
        <PopSingleTeam
          parameters={occurrenceHook.parameters}
          occurrenceHook={occurrenceHook}
          open={occurrenceHook.openSingleTeam}
          executeActionPop={executeActionPop}
          handleCancel={cancel}
        ></PopSingleTeam>
      )}
    </div>
  );
}
