import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import functions from "../../../../../utils/functions";

const useStyles = makeStyles((theme) => ({
  boxTitular: {
    height: 100,
    width: 70,
    display: "inline-block",
    position: "relative",
    borderRadius: 5,
    backgroundColor: "white",
    borderColor: theme.palette.text.main,
    "& input, p": {
      color: theme.palette.text.dark,
    },
  },
  divider: {
    background: theme.palette.divider,
    width: 69,
  },
  nickname: {
    paddingTop: 8,
    marginBottom: 3,
    fontWeight: 600,
    fontSize: 12,
    maxWidth: '70%',
    overflow: 'hidden',
    textTransform: "lowercase",
    "&:first-letter": {
      textTransform: "capitalize",
    },
  },
  position: {
    paddingTop: 3,
    fontSize: 11,
    textAlign: 'center',
    color: "#000000",
  },
  sigla: {
    fontSize: 30,
    marginTop: 15,
    padding: 0,
  },
}));

export default function TechnicalCard({ player }) {
  const classes = useStyles();
  const technicalFunction = functions.getTechnicalFunction(
    player.technicalFunction
  );
  return (
    <Box border={1} className={classes.boxTitular}>
      <Grid
        container
        spacing={2}
        direction="column"
        justify="center"
        alignItems="center"
        alignContent="flex-end"
        wrap="nowrap"
      >
        <Typography variant="h2" className={classes.sigla}>
          {technicalFunction.sigla}
        </Typography>
        <Typography variant="body1" className={classes.nickname}>
          {functions.setNicknameWith(player, 8)}
        </Typography>
        <Divider className={classes.divider} />
        <Tooltip title={technicalFunction.name}>
          <Typography variant="body2" className={classes.position}>
            {technicalFunction.shortName}
          </Typography>
        </Tooltip>
      </Grid>
    </Box>
  );
}
