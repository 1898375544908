import React, { useRef } from 'react'

import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import Grid from '@material-ui/core/Grid';
import { makeStyles } from "@material-ui/core/styles";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import Enter from '../../theme/animations/enter.animations'

const useStyles = makeStyles((theme) => ({
  button: {
    display: 'inline-block',
    maxWidth: 50,
  },
  availableButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: "solid 1px",
    height: 110,
    width: 50,
    borderRadius: 5,
    backgroundColor: "#f3f3f3",
    borderColor: theme.palette.action.disabled,
    textAlign: "center",
    cursor: "pointer",
    "& svg": {
      fontWeight: 600,
      fontSize: 26,
      color: theme.palette.action.cancel,
      paddingTop: 5,
    },
  },
  disabledButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: "solid 1px",
    height: 110,
    width: 50,
    borderRadius: 5,
    backgroundColor: theme.palette.gray,
    borderColor: theme.palette.gray,
    textAlign: "center",
    "& svg": {
      fontWeight: 600,
      fontSize: 26,
      color: theme.palette.white,
      paddingTop: 5,
    },
  },
}))

const NextArrow = ({ currentSlide, slideCount, carouselRef, handleCallback }) => {


  
  const classes = useStyles();
  return (
    <Grid className={classes.button}>
      <Enter>
        <Grid
          className={currentSlide === (slideCount - 1) ? classes.disabledButton : classes.availableButton}
          onClick={() => {
            if (carouselRef) {
              handleCallback();
              const totalOfSlides = carouselRef.props.children.length - 1
              return carouselRef.slickGoTo(currentSlide < totalOfSlides ? ++currentSlide : totalOfSlides);
            }
          }}
        >
          <Grid item>
            <ArrowForwardIcon />
          </Grid>
        </Grid>
      </Enter>
    </Grid>
  )
}

const PrevArrow = ({ currentSlide, carouselRef }) => {
  const classes = useStyles();
  return (
    <Grid className={classes.button}>
      <Enter>
        <Grid
          className={currentSlide === 0 ? classes.disabledButton : classes.availableButton}
          onClick={() => {
            if (carouselRef) {
              carouselRef.slickGoTo(currentSlide > 0 ? --currentSlide : 0);
            }
          }}
        >
          <Grid item>
            <ArrowBackIcon />
          </Grid>
        </Grid>
      </Enter>
    </Grid>
  )
}

const CarouselCards = ({ children, occurrencePeriodsHook, handleCallback2  }) => {
  const handleCallback = () =>{
    console.log("who.childData", "handleCallback")
    handleCallback2();
  }
  const carouselRef = useRef(null);

  let settings = {
    infinite: false,
    dots: true,
    slidesToShow: 1,
    swipe: false,
    touchMove: false,
    slidesToScroll: 1,
    initialSlide: occurrencePeriodsHook.currentSlide,
    prevArrow: <PrevArrow carouselRef={carouselRef.current} />,
    nextArrow: <NextArrow carouselRef={carouselRef.current} handleCallback={handleCallback} />
  }

  return (
    <Slider ref={carouselRef} {...settings}>
      { children}
    </Slider>
  )
}

export default CarouselCards
