import { useState, useContext } from 'react';
import { MatchContext } from '../../../../../components/contexts/match.provider';

export default function useTechnicalHook(team) {
  // Lista de Titulares
  const [technicals, setTechnicals] = useState(team.technicalMembers);
  const mainContext = useContext(MatchContext);

  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('nickName');
  const [applyFilter, setApplyFilter] = useState(true);

  const updatePlayer = (player) => {
    setTechnicals(
      technicals.map((item) => (item.id === player.id ? player : item))
    );
    mainContext.updatePlayer(player);
    mainContext.updateMatch();
  };

  return {
    // Jogadores
    members: technicals,
    order: order,
    orderBy: orderBy,
    applyFilter: applyFilter,
    setApplyFilter: setApplyFilter,

    updatePlayer: updatePlayer,
    // ----------------------------------------------------------
    // Escala o Jogador como Titular
    // ----------------------------------------------------------
    setStarter: (player, value) => {
      player.isStarter = value;
      updatePlayer(player);
    },

    // ----------------------------------------------------------
    // Altera a função do membro da equipe técnica
    // ----------------------------------------------------------
    changeFunction: (player, value) => {
      player.technicalFunction = value;
      updatePlayer(player);
    },
    // ----------------------------------------------------------
    // SetOrder
    // ----------------------------------------------------------
    setOrder: (order) => {
      setOrder(order ? 'desc' : 'asc');
    },

    // ----------------------------------------------------------
    // SetOrderBy
    // ----------------------------------------------------------
    setOrderBy: (orderBy) => {
      setOrderBy(orderBy);
    },
  };
}
