import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

// My Components
import HeaderToogle from './components/header.toogle';
import Logout from './components/logout';
import shieldActive from '../../../assets/images/active.png';
import img from '../../../assets/images/background_image.png';
import Clock from '../../clock';
import ReportButton from '../../pops/Report/ReportButton';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    background: `url(${img}) no-repeat top 10% center`,
    color: 'white',
    height: 120,
    paddingLeft: 50,
    paddingRight: 50,
    maxWidth: 2000,
    margin: '0 auto',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  cupSection: {
    maxWidth: 440,
    alignItems: 'center',
  },
  title: {
    fontSize: 16,
  },
  red: {
    backgroundColor: 'red',
  },
  subTitle: {
    color: 'white',
    fontWeight: 600,
    fontSize: 11,
  },
  shield: {
    marginRight: 20,
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  sumulaSection: {
    maxWidth: 280,
  },
  clockSection: {
    maxWidth: 460,
  },
  clock: {
    width: 245,
    height: 46,
    marginRight: -26,
  },
  reportButton: {
    width: 116,
  },
  logoutButton: {
    marginLeft: 10,
  },
}));

export default function MainHeader({ mainContext, occurrenceContext }) {
  const classes = useStyles();

  return (
    <Grid
      className={classes.root}
      container
      spacing={0}
      direction="row"
      justify="space-between"
      alignItems="center"
      alignContent="center"
      wrap="nowrap"
    >
      <Grid item direction="row" container className={classes.cupSection}>
        <Grid item>
          <img className={classes.shield} src={shieldActive} alt=""></img>
        </Grid>
        <Grid item>
          <Typography className={classes.title} variant="h3" color="inherit">
            {mainContext.match.competition.name}
          </Typography>
          <Typography variant="body1" className={classes.subTitle}>
            {mainContext.match.competition.modality} {' - '}
            {mainContext.match.competition.division}
            {' - '}
            {mainContext.match.competition.category}
            {' - '}
            {mainContext.match.group?.name}
            {' - '}
            {mainContext.match.phase?.name}
            {' - '} {mainContext.match.competition.season}
          </Typography>
          {mainContext.match.status && (
            <Typography variant="body1" className={classes.subTitle}>
              {' Status: '}
              {mainContext.match.status.name}
            </Typography>
          )}
        </Grid>
      </Grid>
      <Grid item container justify="center" className={classes.sumulaSection}>
        <Box>
          <HeaderToogle
            mainContext={mainContext}
            occurrenceContext={occurrenceContext}
          ></HeaderToogle>
        </Box>
      </Grid>
      <Grid
        item
        container
        justify="flex-end"
        alignItems="center"
        alignContent="center"
        className={classes.clockSection}
      >
        {occurrenceContext.cronometerVisible && mainContext.index === 0 && (
          <Grid item className={classes.clock}>
            <Clock
              mainContext={mainContext}
              occurrenceContext={occurrenceContext}
              allWaysVisible={mainContext.index === 0}
            ></Clock>
          </Grid>
        )}

        <Grid item className={classes.reportButton}>
          <ReportButton matchId={mainContext.match.id}></ReportButton>
        </Grid>
        <Grid item className={classes.logoutButton}>
          <Logout />
        </Grid>
      </Grid>
    </Grid>
  );
}
