import { api } from '../services/api.services';

export default function PlayerServices() {
  const updatePlayer = async (match, team, players) => {
    try {
      let objplayer = {
        matchId: match.id,
        competitionId: match.competition.id,
        teamId: team.id,
        players: players,
      };

      var resp = await api.post('team/update', objplayer);
      return resp;
    } catch (error) {
      return error;
    }
  };

  const playerDocs = async (playerId) => {
    try {
      const res = await api.get(`player/${playerId}/docs`);

      return res.data;
    } catch (error) {
      return error;
    }
  };

  const playerDocsReject = async (playerId) => {
    try {
      return await api.put(`player/${playerId}/reject`);
    } catch (error) {
      return error;
    }
  };

  const playerDocsApprove = async (playerId) => {
    try {
      return await api.put(`player/${playerId}/approve`);
    } catch (error) {
      return error;
    }
  };

  return {
    updatePlayer: updatePlayer,
    playerDocs: playerDocs,
    playerDocsReject: playerDocsReject,
    playerDocsApprove: playerDocsApprove,
  };
}
