import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Container from "@material-ui/core/Container";
import StepConnector from "@material-ui/core/StepConnector";
import ShieldStepIcon from "./components/ShieldStepIcon";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 2,
    "& .MuiStepLabel-labelContainer": {
      paddingRight: 10,
      "& h6": {
        color: "lightgrey",
      },
      "& .MuiStepLabel-active>h6": {
        color: theme.palette.action.main,
      },
    },
  },
}));

const QontoConnector = withStyles({
  alternativeLabel: {
    top: 20,
    left: "calc(-50% + 18px)",
    right: "calc(50% + 30px)",
  },
  active: {
    "& $line": {
      borderColor: "#cccccc",
    },
  },
  completed: {
    "& $line": {
      borderColor: "#cccccc",
    },
  },
  line: {
    borderColor: "#eaeaf0",
    borderTopWidth: 2,
    borderRadius: 1,
  },
})(StepConnector);

export default function SubHeader({ mainContext }) {
  const classes = useStyles();

  const steps = [
    {
      id: 1,
      team: mainContext.match.home.name,
      type: "Time Mandante",
    },
    { id: 2, team: mainContext.match.away.name, type: "Time Visitante" },
    { id: 3, team: "Arbitragem", type: "Quadro de" },
  ];

  return (
    <div className={classes.root}>
      <Container maxWidth="md">
        <Stepper
          activeStep={mainContext.step}
          alternativeLabel
          connector={<QontoConnector />}
        >
          {steps.map((step, index) => (
            <Step
              onClick={async () => {
                mainContext.updateStep(index);
              }}
              key={step.id}
            >
              <StepLabel StepIconComponent={ShieldStepIcon}>
                <Typography variant="caption" display="block">
                  {step.type}
                </Typography>
                <Typography variant="h6">{step.team}</Typography>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Container>
    </div>
  );
}
