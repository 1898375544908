export default {
  root: {
    "&::placeholder": {
      textOverflow: "ellipsis !important",
      color: "blue",
      fontSize: 14,
    },
    borderColor: "#cccccc",
    "& .MuiFormLabel-root": {
      color: "#25303d",
      position: "absolute",
      top: -5,
      "&.Mui-focused": {
        color: "#25303d",
      },
    },
    "& .MuiOutlinedInput-root": {
      backgroundColor: "white",
      height: 45,
      "&.Mui-focused fieldset": {
        borderColor: "#cccccc",
        border: "1px solid #cccccc",
      },
    },
    "& .MuiFormHelperText-root": {
      fontSize: "10px",
      color: "red",
      marginBottom: -20,
    },
    "& .MuiFormHelperText-contained": {
      marginLeft: 0,
      marginRight: 0,
    },
  },
};
