import React from 'react'

import { Grid, makeStyles } from '@material-ui/core'
import Close from '@material-ui/icons/Close'
import FButton from './FButton'

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: 12,
    right: 15,
  },
  button: {
      backgroundColor: '#fff !important',
      color: `${theme.palette.action.cancel} !important`,
      width: '30px !important',
      minWidth: '30px !important',
      maxHeight: '30px !important',
      "& *": {
          fontSize: 28,
      }
  },
}))

export default function CloseButton({onClick}) {
  const classes = useStyles()
  
  return (
    <Grid className={classes.root}>
      <FButton className={classes.button} onClick={onClick}>
        <Close />
      </FButton>
    </Grid>
  )
}