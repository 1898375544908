import { useState, useContext } from "react";
import { api } from "../../../../services/api.services";
import { MatchContext } from "../.././../../components/contexts/match.provider";

export default function useLoginHook() {
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState({});
  const matchContext = useContext(MatchContext);

  const login = async (credentials) => {

    setLoading(true);
    try {
      var response = await api.post("auth/authenticate", credentials);
      setResponse(prev => response);
      console.log("who.matchContext.response.data", response.data)
      matchContext.load(response.data);
      setLoading(false);
      return response;
    } catch (error) {
      setResponse(prev => error);
      return error;
    } finally {
      setLoading(false);
    }

  }
  return {
    loading,
    response,
    login
  }
}
