import React, { useCallback, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import clsx from 'clsx';

import functions from '../../../../../utils/functions';

const useStyles = makeStyles((theme) => ({
  boxPlayer: {
    height: 130,
    width: 71,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'space-between',
    borderRadius: 5,
    backgroundColor: 'white',
    borderColor: theme.palette.text.light,
    '& input, p, hr, h4': {
      color: theme.palette.text.primary,
    },
    cursor: 'pointer',
    position: 'relative',
  },
  boxPlayerDeactive: {
    height: 130,
    width: 71,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    borderRadius: 5,
    backgroundColor: 'white',
    borderColor: theme.palette.text.light,
    '& input, p, hr, h4': {
      color: theme.palette.text.light,
    },
    cursor: 'auto',
  },
  boxPlayerActive: {
    borderColor: theme.palette.text.primary,
    backgroundColor: '#fdfdfd',
    borderWidth: 2,
    cursor: 'pointer',
    '& $divider': {
      width: 66,
    },
    '& $inputClass': {
      paddingTop: 0,
    },
    '& $cards': {
      width: 33,
      height: 22,
      '@supports ( -moz-appearance:none )': {
        height: 20,
        marginBottom: 1,
      },
    },
  },
  divider: {
    background: theme.palette.divider,
    width: 69,
  },
  number: {
    fontSize: 32,
    fontWeight: 600,
  },
  goals: {
    maxWidth: '49%',
    flexBasis: '49%',
  },
  cards: {
    width: 34,
    height: 21,
    '@supports ( -moz-appearance:none )': {
      height: 19,
      marginBottom: 1.3,
    },
  },
  nickname: {
    fontWeight: 600,
    fontSize: 12,
    textAlign: 'center',
    textTransform: 'lowercase',
    '&:first-letter': {
      textTransform: 'capitalize',
    },
  },
  badgeBox: {
    position: 'absolute',
    top: -6,
    right: -6,
    color: 'white',
    width: 18,
    height: 37,
    verticalAlign: 'center',
    textAlign: 'center',
  },
  capitain: {
    lineHeight: 1,
    backgroundColor: theme.palette.action.secondary,
    width: 16,
    height: 16,
  },
  golkeeper: {
    lineHeight: 1,
    backgroundColor: theme.palette.action.disabled,
    width: 16,
    height: 16,
  },
  badgeBoxMvp: {
    position: 'absolute',
    top: -6,
    right: -6,
    color: 'white',
    width: 18,
    height: 37,
    verticalAlign: 'center',
    textAlign: 'center',
  },
  mvp: {
    position: 'absolute',
    top: -8,
    left: 20,
    lineHeight: 1,
    backgroundColor: theme.palette.primary.main,
    width: 29,
    height: 16,
  },

  title: {
    color: 'white !important',
    fontSize: 13,
    fontWeight: 600,
    paddingTop: 2,
    lineHeight: 0.9,
  },
  inputClass: {
    width: 60,
    textAlign: 'center',
    paddingTop: 3,
  },
  position: {
    fontSize: 12,
    textAlign: 'center',
    marginTop: 10,
  },
  footer: {},
  center: {
    textAlign: 'center',
  },
  yellow: {
    backgroundColor: '#edd20a',
    borderBottomRightRadius: 4,
  },
  red: {
    backgroundColor: theme.palette.error.main,
    borderBottomRightRadius: 4,
    '& input, p, hr, h4': {
      color: theme.palette.common.white,
    },
  },
}));

export default function PlayerPopCard({
  occurrenceHook,
  team,
  player,
  setSelected,
}) {
  const classes = useStyles();

  const cannotBeSelected = () => {
    return player.enableToSubstituition === 0;
  };

  const isGolKeeper = (player) => {
    return player.position && player.position.id === 1;
  };

  const isSelected = () => {
    return occurrenceHook.selectedPlayers.find((x) => x.id === player.id);
  };

  const select = () => {
    if (cannotBeSelected()) return;
    if (setSelected) setSelected(player, team);
  };

  const getGols = () => {
    let total = occurrenceHook.getGols(
      undefined,
      undefined,
      undefined,
      player.id
    );
    return `${total.length}G`;
  };

  const getFaults = () => {
    let total = occurrenceHook.getFaults(undefined, player.id);
    return `${total.length}F`;
  };

  const getYellowCards = () => {
    let total = occurrenceHook.getYellowCards(player.id);
    return total;
  };

  const getRedCards = useCallback(() => {
    let total = occurrenceHook.getRedCards(player.id);
    return total;
  }, [occurrenceHook, player.id]);

  useEffect(() => {
    player.hasRedCard = getRedCards() !== undefined;
  }, [player, getRedCards]);

  return (
    <Box
      border={1}
      className={clsx(classes.boxPlayer, {
        [classes.boxPlayerActive]: isSelected(),
        [classes.boxPlayerDeactive]: getRedCards(),
      })}
      onClick={select}
    >
      {occurrenceHook.match?.mostValuablePlayer?.id === player?.id && (
        <Grid
          container
          direction="column"
          justify="flex-end"
          alignItems="center"
          alignContent="center"
          wrap="nowrap"
        >
          <Grid item xs={12}>
            <Tooltip title="MVP">
              <Box borderRadius={2} className={classes.mvp}>
                <Typography variant="body2" className={classes.title}>
                  mvp
                </Typography>
              </Box>
            </Tooltip>
          </Grid>
        </Grid>
      )}

      <Grid
        container
        direction="column"
        justify="flex-end"
        alignItems="center"
        alignContent="flex-end"
        wrap="nowrap"
      >
        {/* Caso seja capitão */}

        <Grid
          className={classes.badgeBox}
          container
          direction="column"
          alignItems="center"
          justify="space-between"
          wrap="nowrap"
        >
          {player.isCaptain && (
            <Grid item xs={12}>
              <Tooltip title="Capitão(ã)">
                <Box borderRadius={2} className={classes.capitain}>
                  <Typography variant="body2" className={classes.title}>
                    C
                  </Typography>
                </Box>
              </Tooltip>
            </Grid>
          )}

          {isGolKeeper(player) && (
            <Grid item xs={12}>
              <Tooltip title="Goleiro(a)">
                <Box borderRadius={2} className={classes.golkeeper}>
                  <Typography variant="body2" className={classes.title}>
                    G
                  </Typography>
                </Box>
              </Tooltip>
            </Grid>
          )}
        </Grid>

        <Grid item className={classes.inputClass}>
          <Typography variant="h4" className={classes.number}>
            {player.defaultNumber}
          </Typography>
        </Grid>
        <Grid item>
          <Tooltip title={player.name}>
            <Typography variant="h4" className={classes.nickname}>
              {functions.setNicknameWith(player, 8)}
            </Typography>
          </Tooltip>
        </Grid>
        <Grid item className={classes.position}>
          <Typography variant="body2">
            {!Boolean(/\s/g.test(player?.position?.name)) &&
            String(player?.position?.name).length > 8
              ? player?.position?.name.slice(0, 8)
              : player?.position?.name}
          </Typography>
        </Grid>

        <Grid
          item
          container
          alignItems="center"
          justify="center"
          className={classes.footer}
          style={{
            marginTop: !Boolean(/\s/g.test(player?.position?.name)) && 20,
          }}
        >
          <Divider className={classes.divider} />
          <Grid item className={classes.goals}>
            <Tooltip title="Gols">
              <Typography variant="body2" className={classes.center}>
                {getGols()}
              </Typography>
            </Tooltip>
          </Grid>
          <Divider orientation="vertical" flexItem />
          <Grid
            item
            className={clsx(classes.cards, {
              [classes.yellow]: getYellowCards(),
              [classes.red]: getRedCards(),
            })}
          >
            <Tooltip title="Faltas">
              <Typography variant="body2" className={classes.center}>
                {getFaults()}
              </Typography>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
