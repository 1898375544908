import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import ArrowRight from "@material-ui/icons/ArrowForward";
import ArrowBack from "@material-ui/icons/ArrowBack";

import FButton from "../../../../../components/button/FButton";

const useStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: 60,
        paddingRight: 60,
        paddingBottom: 60,
        [theme.breakpoints.down('md')]: {
            paddingLeft: 30,
            paddingRight: 30,
        },
        maxWidth: 2015,
        margin: '0 auto',
    },
    btnLarge: {
        fontSize: "17px !important",
        [theme.breakpoints.down('md')]: {
            fontSize: "16px !important",
        },
        position: "relative",
        "& .MuiButton-endIcon": {
            right: 15,
            position: "absolute",
        },
    },
    btnLargeBack: {
        fontSize: "17px !important",
        [theme.breakpoints.down('md')]: {
            fontSize: "16px !important",
        },
        position: "relative",
        "& .MuiButton-startIcon": {
            left: 15,
            position: "absolute",
        },
    },
    icon: {
        fontSize: "25px !important",
        fontWeight: 600
    },
    buttonsWidth: {
        width: 300,
    }
}));

export default function MatchFooter({ mainContext }) {
    const classes = useStyles();

    const goToHome = () => mainContext.updateStep(0);
    const goToAway = () => mainContext.updateStep(1);
    const goToReferees = () => mainContext.updateStep(2);
    const goToSumula = async () => {
        await mainContext.updateStep(2);
        mainContext.selectIndex(null, 1);
    }

    return (
        <div className={classes.root}>
            {/* Escalar Visitante */}
            {mainContext.step === 0 && (
                <Grid container justify="flex-end" alignItems="center">
                    <Grid item className={classes.buttonsWidth}>
                        <FButton
                            className={classes.btnLarge}
                            fullWidth
                            type="secondary"
                            onClick={goToAway}
                            endIcon={<ArrowRight className={classes.icon}></ArrowRight>}
                        >
                            Escalar Time Visitante
                        </FButton>
                    </Grid>
                </Grid>
            )}

            {/* Escalar Mandante */}
            {mainContext.step === 1 && (
                <Grid container justify="space-between">
                    <Grid item className={classes.buttonsWidth}>
                        <FButton
                            className={classes.btnLargeBack}
                            fullWidth
                            type="secondary"
                            onClick={goToHome}
                            startIcon={<ArrowBack className={classes.icon}></ArrowBack>}
                        >
                            Escalar Time Mandante
            </FButton>
                    </Grid>

                    <Grid item className={classes.buttonsWidth}>
                        <FButton
                            className={classes.btnLarge}
                            fullWidth
                            type="secondary"
                            onClick={goToReferees}
                            endIcon={<ArrowRight className={classes.icon}></ArrowRight>}
                        >
                            Escalar Arbitragem
            </FButton>
                    </Grid>
                </Grid>
            )}

            {mainContext.step === 2 && (
                <Grid container justify="space-between" alignItems="flex-end">
                    <Grid item className={classes.buttonsWidth}>
                        <FButton
                            className={classes.btnLargeBack}
                            fullWidth
                            type="secondary"
                            onClick={goToAway}
                            startIcon={<ArrowBack className={classes.icon}></ArrowBack>}
                        >
                            Escalar Time Visitante
            </FButton>
                    </Grid>

                    <Grid item className={classes.buttonsWidth}>
                        <FButton
                            className={classes.btnLarge}
                            fullWidth
                            type="main"
                            onClick={goToSumula}
                            endIcon={<ArrowRight className={classes.icon}></ArrowRight>}
                        >
                            Preencher Súmula
            </FButton>
                    </Grid>
                </Grid>
            )}
        </div>
    );
}
