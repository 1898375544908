import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
//import { MatchContext } from "../../../components/contexts/match.provider";

// My components
import FButton from "./../../button/FButton"
import PopReport from "./index";

const useStyles = makeStyles((theme) => ({
    root: {
        fontSize: '13px !important'
    },
}));

// My components

export default function ReportButton({ matchId }) {
    const classes = useStyles();

    const [open, setOpen] = useState(false);

    const openPopReport = (value) => {
        setOpen(prev => value);
    };

    return (
        <div>

            {
                open &&
                <PopReport
                    matchId={matchId}
                    open={open}
                    handleOk={() => openPopReport(false)}
                    handleCancel={() => openPopReport(false)}
                ></PopReport>
            }

            <FButton
                fullWidth
                className={classes.root}
                onClick={() => openPopReport(true)}
                thin type="main">
                Gerar Súmula
            </FButton>
        </div>
    );
}
