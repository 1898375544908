import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";

// My Components
import FButton from "../../../../../components/button/FButton";
// import FCard from "../../../../../theme/animations/fcard.animations";
// import PlayerPopCard from "../../components/cards/player.pop.card";
import CloseButton from '../../../../../components/button/CloseButton'

import PopHeader from '../../components/popHeader';
import PopTeamBody from '../../components/popTeamBody';
import PopBigBox from "../../components/popBigBox";

const useStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(2),
    },
    grid: {
        flexGrow: 1,
        maxWidth: '47%',
        flexBasis: '47%',
    },
    dialogPaper: {
        position: 'relative',
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        maxWidth: 1200,
        width: '100%',
        paddingTop: 15,
    },
    divider: {
        width: 1,
        height: 540,
    },
    body: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        alignItems: 'flex-start',
        textAlign: 'center',
        maxHeight: "52vh",
        overflowY: 'auto',
        overflowX: 'hidden',
    },
    teamBox: {
        width: '49%',
    },
    title: {
        fontSize: 25,
        paddingBottom: theme.spacing(1),
    },
    subtitle: {
        color: theme.palette.action.cancel,
    },
    spacer: {
        width: 20
    },
    dividerMargin: {
        marginLeft: 30,
        marginRight: 30
    },
    actions: {
        display: "flex",
        paddingRight: theme.spacing(0),
        paddingTop: theme.spacing(2),
        justifyContent: "flex-end",
    },
    cancel: {
        marginRight: theme.spacing(2),
    },
    buttons: {
        width: 190
    },
    row: {
        height: 55,
    },
}));

export default function PopMultiPlayer({ occurrenceHook, parameters, open, handleCancel, executeActionPop }) {
    // Styles
    const classes = useStyles();

    const activeTime = occurrenceHook.activeOccurrenceTime;


    const initValue = () => {
        if (occurrenceHook.selectedOccurrence) {
            return occurrenceHook.selectedOccurrence.occurrenceTime;
        }
        // let min = occurrenceHook.sanatizeUnit(occurrenceHook.cronometerValue.getMinutes());
        // let sec = occurrenceHook.sanatizeUnit(occurrenceHook.cronometerValue.getSeconds());
        // return `${min}:${sec}`;
        return occurrenceHook.getCalculatedCronometer()
    }


    const [timeValue, setTimeValue] = useState(initValue());
    const isTimerValid = (timer) => {

        if (timer.length !== 5 || timer[2] !== ':') {
            return false
        }
        for (let index = 0; index < timer.length; index++) {

            if (index !== 2 && isNaN(timer[index])) {

                return false;
            }

        }
        return true

    }

    const [timerValid, setTimerValid] = useState(isTimerValid(timeValue))
    const setMultiPlayer = (player) => {
        occurrenceHook.selectMultiPlayer(player);
    }

    const getSelected = (player) => {
        return occurrenceHook.selectedPlayers.find(x => x.id === player.id);
    }

    const execute = () => {
        if (executeActionPop) {

            let occurrence = occurrenceHook.selectedOccurrence ? occurrenceHook.selectedOccurrence : {}
            occurrence.occurrenceTime = timeValue;
            occurrence.occurrenceType = parameters.type;

            executeActionPop(occurrence);
            handleCancel();
        }
    }

    return (
        <div>
            <Dialog
                classes={{ paper: classes.dialogPaper }}
                open={open}
                onClose={handleCancel}
                maxWidth="sm"
                disableBackdropClick={true}
            >
                <CloseButton onClick={handleCancel} />
                <Grid
                    container
                    className={classes.root}
                    justify="flex-start"
                    direction="column"
                >
                    {/* Header */}
                    <PopHeader type={parameters.type} period={activeTime}></PopHeader>

                    {/* Body */}
                    <Grid container direction="row" alignItems="center" justify="space-around" className={classes.body}>
                        <Grid item className={classes.teamBox}>
                            <PopTeamBody
                                title='time mandante'
                                team={occurrenceHook.match.home}
                                showReserves={parameters.showReserves}
                                showTechnical={parameters.showTechnical}
                                getSelected={getSelected}
                                setPlayer={setMultiPlayer}>
                            </PopTeamBody>
                        </Grid>
                        <Divider orientation="vertical" flexItem />
                        <Grid item className={classes.teamBox}>
                            <PopTeamBody
                                title='time visitante'
                                team={occurrenceHook.match.away}
                                showReserves={parameters.showReserves}
                                showTechnical={parameters.showTechnical}
                                getSelected={getSelected}
                                setPlayer={setMultiPlayer}>
                            </PopTeamBody>
                        </Grid>
                    </Grid>

                    {/* BigBox */}
                    <PopBigBox timeValue={timeValue} setTimeValue={setTimeValue} handleTimerValid={setTimerValid}>

                    </PopBigBox>

                    <div className={classes.actions}>
                        <FButton thin={true}
                            onClick={handleCancel}
                            className={classes.buttons}
                            type="cancel">
                            Cancelar
                        </FButton>
                        <div className={classes.spacer}></div>
                        <FButton
                            disabled={(!occurrenceHook.selectedPlayers.length > 0 && timerValid) || !timerValid}
                            thin={true}
                            className={classes.buttons}
                            type="main"
                            onClick={execute}
                        >
                            Confirmar
                        </FButton>
                    </div>
                </Grid>
            </Dialog>
        </div>
    )
}