import React from 'react'
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";

// My component
import shieldActive from "../../../../../../assets/images/active.png";
import useOccurrenceHook from "../../../components/hooks/occorrence.hook";

const useStyles = makeStyles((theme) => ({
    box: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        textAlign: "center"
    },
    teamName: {
        fontSize: 25
    },
    colors: {
        fontSize: 14
    },
    faults: {
        padding: 5,
        fontSize: 14,
        color: theme.palette.action.main
    },
    red: {
        backgroundColor: "red",
    },
}));

export default function ScoreBoardTeam({ title, team, showFaults = true, showShield = true }) {

    const classes = useStyles();

    const occurrenceHook = useOccurrenceHook();


    const getFaults = () => {
        let faults = occurrenceHook.getFaults(team.id);
        return `Faltas acumuladas no jogo : ${faults.length}`;
    }

    const getFaultsByTime = () => {
        let faults = occurrenceHook.activeOccurrenceTime
        let faultsByTime = occurrenceHook.getFaultsByTime(team.id, faults?.gameTime?.id);

        return `Faltas acumuladas no tempo : ${faultsByTime.length}`;
    }
    var faults = getFaults();

    var faultsByTime = getFaultsByTime();

    return (
        <Grid item container
            direction="column"
            justify="center"
            alignItems="center"
            style={{ textAlign: 'center' }}
        >
            {showShield && (<Grid item > <img className={classes.shield} src={shieldActive} alt=""></img></Grid>)}
            <Grid item  >
                <Typography variant="h5">
                    {title}
                </Typography>
            </Grid>
            <Grid item>
                <Typography variant="h3" className={classes.teamName}>
                    {team.name}
                </Typography>
            </Grid>
            <Grid item  >
                <Typography variant="h5" className={classes.colors}>
                    ({team.colors})
                </Typography>
            </Grid>
            {showFaults && (
                <Grid item>
                    <Typography variant="h5" className={classes.faults}>
                        {faultsByTime}
                        <br />
                        {faults}
                    </Typography>
                </Grid>
            )}
        </Grid>
    );
}