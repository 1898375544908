import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';

// My Components
import FButton from '../../../../../../components/button/FButton';
import TimeCard from '../../cards/time.card';
import Enter from '../../../../../../theme/animations/enter.animations';
import PopAddTime from '../../../pops/addTime/index';
import Carousel from '../../../../../../components/carousel';
import { Box, Modal, Typography } from '@material-ui/core';
import useClockHook from '../../../../../../components/clock/clock.hook';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },
  buttons: {},
  timeSection: {},
  loadIcon: {
    height: 120,
    minWidth: 80,
    textAlign: 'center',
  },
  times: {
    width: 457,
    display: 'flex',
    justifyContent: 'space-between',
  },
  selectTime: {
    width: 290,
    '& .slick-slider': {
      display: 'flex',
    },
    '& .slick-slide': {
      paddingLeft: 15,
    },
    '& *': {
      outline: 'none',
    },
  },
  addbuttom: {
    border: 'solid 1px',
    height: 110,
    minWidth: 50,
    borderRadius: 5,
    backgroundColor: theme.palette.action.cancel,
    borderColor: theme.palette.action.disabled,
    textAlign: 'center',
    cursor: 'pointer',
    '& *': {
      fontWeight: 600,
      fontSize: 30,
      color: theme.palette.white,
    },
  },
  addbuttomDisabled: {
    border: 'solid 1px',
    height: 110,
    minWidth: 50,
    borderRadius: 5,
    backgroundColor: theme.palette.action.disabled,
    borderColor: theme.palette.action.disabled,
    textAlign: 'center',
    cursor: 'default',
    '& *': {
      fontWeight: 600,
      fontSize: 30,
      color: theme.palette.white,
    },
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    height: 250,
    background: 'white',
    borderRadius: 5,
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  },
}));
export default function TimeEvents({
  occurrenceHook,
  occurrenceContext,
  occurrencePeriodsHook,
  mainContext,
}) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const handleOpenModal = () => {
    setOpen(true);
  };
  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleCallback2 = () => {
    console.log('who.childDataTimeEvents', 'handleCallbackTimeEvents');
  };

  const executeStartEnd = (id) => {
    if (id === 2) {
      occurrencePeriodsHook.dispatch({ type: 'endPeriod' });
    } else {
      occurrencePeriodsHook.dispatch({ type: 'startPeriod' });
    }
  };

  const executeEndMatch = () => {
    occurrenceHook.dispatch({ type: 'endMatch', payload: {} });
    occurrencePeriodsHook.dispatch({ type: 'endMatch', payload: {} });
    handleCloseModal();
  };

  const addNewTime = () => {
    if (!mainContext.isMatchDone) {
      occurrencePeriodsHook.openPopToAdd();
      occurrenceHook.selectOccurrence('');
    }
  };

  const disabled = () => {
    // return occurrencePeriodsHook.isInitOfPeriod === 0 || occurrenceContext.matchIsEnded();
    return occurrencePeriodsHook.isInitOfPeriod === 0;
  };

  const handleOk = (period) => {
    if (
      period.timeType.id === 1 &&
      occurrencePeriodsHook.match.clockComment.clockType.id === 2
    ) {
      occurrenceContext.handlerOnAddTimer();
    }
    occurrencePeriodsHook.setCurrentSlide(occurrencePeriodsHook.periods.length);
    occurrencePeriodsHook.itemAdded();
    setTimeout(() => {
      occurrenceContext.afterHandlerOnAddTimer();
    }, 500);
  };

  const finishTime = () => {
    const isFirstRegularTime =
      occurrenceContext.activeOccurrenceTime.gameTime.id === 1;
    if (
      isFirstRegularTime &&
      occurrencePeriodsHook.match.clockComment.clockType.id === 2 &&
      occurrencePeriodsHook.isInitOfPeriod !== 1
    ) {
      occurrenceContext.handlerOnAddTimer();
    }
    executeStartEnd(occurrencePeriodsHook.isInitOfPeriod);
    setTimeout(() => {
      occurrenceContext.afterHandlerOnAddTimer();
    }, 500);
  };

  return (
    <div>
      {occurrencePeriodsHook.showAdd && (
        <PopAddTime
          occurrencePeriodsHook={occurrencePeriodsHook}
          open={occurrencePeriodsHook.showAdd}
          handleOk={handleOk}
          handleCancel={() => occurrencePeriodsHook.setShowAdd(false)}
          occurrenceContext={occurrenceContext}
        ></PopAddTime>
      )}
      {/* Eventos de Tempo relacionados ao início do jogo */}

      {occurrencePeriodsHook.isLoading && (
        <Grid
          item
          container
          direction="column"
          className={classes.loadIcon}
          justify="center"
          alignItems="center"
        >
          <CircularProgress></CircularProgress>
        </Grid>
      )}

      {!occurrencePeriodsHook.isLoading &&
        occurrencePeriodsHook.periods.length > 0 && (
          <Container maxWidth="xl" className={classes.root}>
            <Grid
              container
              alignItems="center"
              direction="column"
              className={classes.buttons}
              item
              xs={4}
            >
              <Grid
                container
                justify="center"
                alignItems="stretch"
                direction="column"
                spacing={1}
                item
              >
                <Grid item>
                  <FButton
                    disabled={disabled() || mainContext.isMatchDone}
                    fullWidth
                    type="secondary"
                    onClick={() => finishTime()}
                  >
                    {(occurrencePeriodsHook.isInitOfPeriod === 1 ||
                      occurrencePeriodsHook.isInitOfPeriod === 0) &&
                      'Bola rolando'}
                    {occurrencePeriodsHook.isInitOfPeriod === 2 &&
                      'Finalizar tempo'}
                  </FButton>

                  {!occurrencePeriodsHook.isInitOfPeriod === 2 && (
                    <FButton
                      disabled={mainContext.isMatchDone}
                      fullWidth
                      type="secondary"
                      onClick={() => finishTime()}
                    >
                      Finalizar tempo
                    </FButton>
                  )}
                </Grid>
                <Grid item>
                  <FButton
                    fullWidth
                    type="main"
                    //onClick={executeEndMatch}
                    disabled={occurrenceContext.matchIsEnded()}
                    onClick={handleOpenModal}
                  >
                    Encerrar jogo
                  </FButton>
                </Grid>
                <Modal keepMounted open={open} onClose={handleCloseModal}>
                  <Box className={classes.modal}>
                    <Grid container alignItems="center" direction="column">
                      <Typography variant="h4" component={'h6'}>
                        Tem certeza que você deseja encerrar o jogo?
                      </Typography>
                      <Grid
                        container
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                        spacing={2}
                        style={{ marginTop: 30 }}
                      >
                        <Grid item>
                          <FButton
                            fullWidth
                            type="danger"
                            //onClick={executeEndMatch}

                            onClick={handleCloseModal}
                          >
                            Voltar
                          </FButton>
                        </Grid>
                        <Grid item>
                          <FButton
                            fullWidth
                            type="main"
                            onClick={executeEndMatch}
                            disabled={occurrenceContext.matchIsEnded()}
                          >
                            Encerrar jogo
                          </FButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                </Modal>
              </Grid>
            </Grid>

            {/* Galeria de Tempos corridos de Jogo */}
            <Grid
              container
              spacing={2}
              justify="center"
              alignItems="center"
              className={classes.timeSection}
              item
              xs={8}
            >
              <Grid container item className={classes.times}>
                <Grid item className={classes.selectTime}>
                  <Carousel
                    occurrencePeriodsHook={occurrencePeriodsHook}
                    handleCallback2={handleCallback2}
                  >
                    {occurrencePeriodsHook.periods.map((period, index) => (
                      <Grid item key={index}>
                        <Enter>
                          <TimeCard
                            occurrencePeriodsHook={occurrencePeriodsHook}
                            period={period}
                            occurrenceContext={occurrenceContext}
                            occurrenceHook={occurrenceHook}
                            handleDelete={() =>
                              occurrencePeriodsHook.setCurrentSlide(0)
                            }
                            mainContext={mainContext}
                          ></TimeCard>
                        </Enter>
                      </Grid>
                    ))}
                  </Carousel>
                </Grid>
                <Grid item>
                  <Enter>
                    <Grid
                      className={
                        mainContext.isMatchDone
                          ? classes.addbuttomDisabled
                          : classes.addbuttom
                      }
                      container
                      direction="column"
                      justify="center"
                      alignItems="stretch"
                      onClick={addNewTime}
                    >
                      <Grid item>+</Grid>
                    </Grid>
                  </Enter>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        )}
    </div>
  );
}
