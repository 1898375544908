import { useState, useCallback, useEffect, useMemo, useContext } from "react";
import repository from "../../../../../data/repository";
import constants from "../../../../../utils/constants";
import { api } from "../../../../../services/api.services";
import { firstBy } from "thenby";

import { OccurrenceContext } from '../../../../../components/contexts/occurrences.provider'

export default function useOccurrencePeriodsHook(match) {

    const data = useMemo(() => repository(), []);
    const occurrenceContext = useContext(OccurrenceContext);

    const initialPeriods = () => data.getItemParse(constants.PERIODS) === null ? [] : data.getItemParse(constants.PERIODS);

    const [periods, setPeriods] = useState(initialPeriods);
    const [activePeriod, setDefaultActivePeriod] = useState({});
    const [showAdd, setShowAdd] = useState(() => false);

    const initalIndex = () => activePeriod.id !== undefined ? periods.findIndex(x => x.id === activePeriod.id) : 0

    const [currentSlide, setCurrentSlide] = useState(initalIndex);

    const [isInitOfPeriod, setIsInitOfPeriod] = useState(() => 0);
    const [isLoading, setLoading] = useState(() => false);

    const setActiveOccurrenceTime = occurrenceContext.setActiveOccurrenceTime;
    const setReload = occurrenceContext.setReload;
    const sanatizeUnit = occurrenceContext.sanatizeUnit;

    const setActivePeriod = useCallback((period) => {
        setDefaultActivePeriod(period);
        setActiveOccurrenceTime(period);
        setReload(true);
    }, [setActiveOccurrenceTime, setReload])

    const loadPeriods = useCallback(async () => {
        try {
            setLoading(true);
            var resp = await api.get(`match/${match.id}/periods`);

            if (resp && resp.status === 200 && resp.data.length > 0) {
                setPeriods(resp.data);
                setActivePeriod(resp.data[0]);
                data.setItemStringify(constants.PERIODS, resp.data);
            }
            setLoading(false);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    }, [match.id, data, setActivePeriod]);

    useEffect(() => {
        async function load() {
            await loadPeriods();
        }

        load();
    }, [loadPeriods]);

    useEffect(() => {
        if (activePeriod.id !== undefined) setCurrentSlide(prev => periods.findIndex(x => x.id === activePeriod.id));
    }, [periods, activePeriod])

    const orderItens = (items) => {
        items.sort(firstBy(function (v1, v2) { return v1.timeType.id - v2.timeType.id; })
            .thenBy(function (v1, v2) { return v1.gameTime.id - v2.gameTime.id; }))

        return items;
    }

    const addPeriod = async (period) => {
        setLoading(true);
        let response = await api.post("match/periods", period);
        if (response.status === 200) {
            let items = [...periods, response.data];
            setPeriods(orderItens(items));
            setActivePeriod(response.data);
            data.setItemStringify(constants.PERIODS, items);
            occurrenceContext.setReload(true);
        }
        setLoading(false);
    }

    const updatePeriod = async (period) => {
        setLoading(true);
        let response = await api.put("match/periods", period);
        if (response.status === 200) {
            setPeriods(periods.map((item) => (item.id === period.id ? period : item)));
            data.setItemStringify(constants.PERIODS, periods);
        }
        setLoading(false);
    }

    const deletePeriod = async (period) => {
        setLoading(true);
        let response = await api.delete(`match/periods/${period.id}`);
        if (response.status === 200) {
            let items = periods.filter(item => item.id !== period.id);
            setPeriods(items);
            setActivePeriod(periods[0]);
            data.setItemStringify(constants.PERIODS, items);
        }
        setLoading(false);
    }

    const startPeriod = async (period) => {
        setLoading(true);
        let response = await api.post("match/periods/start", period);
        if (response.status === 200) {
            setPeriods(prev => response.data);
            setActivePeriod(prev => period);
            data.setItemStringify(constants.PERIODS, response.data);
            changeStartEndButton();
        }
        setLoading(false);
    }

    const endPeriod = async (period) => {
        setLoading(true);
        let response = await api.post("match/periods/end", period);
        if (response.status === 200) {
            setPeriods(prev => response.data);
            setActivePeriod(prev => period);
            data.setItemStringify(constants.PERIODS, response.data);
            changeStartEndButton();
        }
        setLoading(false);
    }

    const endMatch = async (period) => {
        setLoading(true);
        setActivePeriod(prev => period);
        changeStartEndButton();
        setLoading(false);
    }

    const openPopToAdd = (item) => {
        setShowAdd(true);
    };

    const itemAdded = (item) => {
        setShowAdd(false);
    };

    const configureAndStartPeriod = () => {
        let period = activePeriod;

        let occurrenceTime = occurrenceContext.getCalculatedCronometer();
        var date = new Date();

        let hour = sanatizeUnit(date.getHours());
        let min = sanatizeUnit(date.getMinutes());
        period.occurrenceHourStart = `${hour}:${min}`;
        period.occurrenceTime = occurrenceTime;

        startPeriod(period);
    }

    const configureAndEndPeriod = () => {
        let period = activePeriod;

        let occurrenceTime = occurrenceContext.getCalculatedCronometer();
        var date = new Date();
        let hour = sanatizeUnit(date.getHours());
        let min = sanatizeUnit(date.getMinutes());
        period.occurrenceHourEnd = `${hour}:${min}`;
        period.occurrenceTime = occurrenceTime;

        endPeriod(period);
    }

    const configureAndEndMatch = () => {
        let period = activePeriod;

        let occurrenceTime = occurrenceContext.getCalculatedCronometer();
        var date = new Date();
        let hour = sanatizeUnit(date.getHours());
        let min = sanatizeUnit(date.getMinutes());
        period.occurrenceHourEnd = `${hour}:${min}`;
        period.occurrenceTime = occurrenceTime;

        endMatch(period);
    }

    const changeStartEndButton = useCallback(() => {
        let period = activePeriod;
        if (period.occurrenceHourStart === "--:--") {
            setIsInitOfPeriod(1);
        } else if (period.occurrenceHourEnd === "--:--") {
            setIsInitOfPeriod(2);
        }
        else {
            setIsInitOfPeriod(0);
        }
    }, [activePeriod])

    useEffect(() => {
        changeStartEndButton();
    }, [changeStartEndButton]);

    const customDispatch = async (action) => {
        switch (action.type) {
            case "addPeriod":
                addPeriod(action.payload, action.type === "editPeriod");
                break;
            case "editPeriod":
                updatePeriod(action.payload);
                break;

            case "deletePeriod":
                deletePeriod(action.payload);
                break;

            case "startPeriod":
                configureAndStartPeriod();
                break;
            case "endPeriod":
                configureAndEndPeriod();
                break;
            case "endMatch":
                configureAndEndMatch();
                break;

            default:
        }
    }

    return {
        match: match,
        dispatch: customDispatch,
        periods: periods,
        activePeriod: activePeriod,
        setActivePeriod: setActivePeriod,
        isLoading: isLoading,
        showAdd,
        setShowAdd,
        openPopToAdd,
        isInitOfPeriod,
        itemAdded,
        currentSlide,
        setCurrentSlide
    };
}