import React from 'react'
import { Typography } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";

// my components
import functions from '../../../../../utils/functions'

const useStyles = makeStyles((theme) => ({
    title: {
        fontSize: 25,
        paddingBottom: theme.spacing(1),
    },
    subtitle: {
        color: theme.palette.action.cancel,
    },
}));

export default function PopHeader({ period, type }) {

    // Styles
    const classes = useStyles();

    return (
        <div>
            <Grid container justify="space-between">
                <Grid item xs={6}>
                    <Typography variant="h4" className={classes.title}>
                        {type.name}
                    </Typography>
                </Grid>
                <Grid item container justify="flex-end" xs={6} alignItems="center">
                    <Typography variant="h4" className={classes.subtitle}>
                        {functions.getGameTimeName(period)} - {period.timeType.name}
                    </Typography>
                </Grid>
            </Grid>
            <Divider></Divider>
        </div>
    )
}
