import { useState, useEffect } from "react";
import { app } from "../../../../services/api.services";

function useChangePassword(credentials) {
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState({});

  useEffect(() => {
    async function forgotPassword() {
      setLoading(true);
      try {
        var resp = await app.post("/api/v1/login/updatepassword", credentials);
        setResponse(resp);
      } catch (error) {
        setResponse(error);
      } finally {
        setLoading(false);
      }
    }

    if (credentials.valid) forgotPassword();
  }, [credentials]);

  return [loading, response];
}

export default useChangePassword;
