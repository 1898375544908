import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

import { BrowserRouter } from "react-router-dom";

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  // Desabilitando StrictMode para uso do Material-UI
  // Como mencionado no link : https://github.com/styled-components/styled-components/issues/2154

  // O método findDOMNode do React está caindo em desuso, porém alguns componentes de parcerios
  // assim como MATERIAL - UI ainda o usam. E isso dá pau.
  // <React.StrictMode>

  // </React.StrictMode>,
  document.getElementById("root")
);
