import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import Tooltip from '@material-ui/core/Tooltip';

import functions from '../../../../../utils/functions';

const useStyles = makeStyles((theme) => ({
  boxPlayer: {
    height: 100,
    minWidth: 90,
    display: 'inline-block',
    position: 'relative',
    borderRadius: 5,
    backgroundColor: 'white',
    borderColor: theme.palette.text.secondary,
    '& input, p, hr, h4': {
      color: theme.palette.text.primary,
    },
    cursor: '-webkit-grab; cursor: grab',
  },

  boxInvalidPlayer: {
    height: 100,
    minWidth: 90,
    display: 'inline-block',
    position: 'relative',
    borderRadius: 5,
    borderWidth: 2,
    backgroundColor: 'white',
    borderColor: 'red',
    '& input, p, hr, h4': {
      color: theme.palette.text.primary,
    },
    cursor: '-webkit-grab; cursor: grab',
  },

  divider: {
    background: theme.palette.divider,
    width: 90,
  },
  number: {
    fontSize: 32,
    padding: 0,
    fontWeight: 600,
    cursor: 'text',
  },
  nicknameBox: {
    paddingBottom: 8,
  },
  nickname: {
    fontWeight: 600,
    fontSize: 12,
    textTransform: 'lowercase',
    '&:first-letter': {
      textTransform: 'capitalize',
    },
  },
  badgeBox: {
    position: 'absolute',
    top: -6,
    right: -6,
    color: 'white',
    width: 18,
    height: 37,
    verticalAlign: 'center',
    textAlign: 'center',
  },
  capitain: {
    lineHeight: 1,
    backgroundColor: theme.palette.action.secondary,
    width: 16,
    height: 16,
  },
  golkeeper: {
    lineHeight: 1,
    backgroundColor: theme.palette.action.disabled,
    width: 16,
    height: 16,
  },
  title: {
    color: 'white !important',
    fontSize: 13,
    fontWeight: 600,
    paddingTop: 2,
    lineHeight: 0.9,
  },
  inputClass: {
    width: 60,
  },
  position: {
    paddingTop: 2,
    fontSize: 12,
  },
}));

export default function PlayerCard({
  player,
  key,
  playersHook,
  index,
  mainContext,
}) {
  const classes = useStyles();

  const isGolKeeper = (player) => {
    return player.position && player.position.id === 1;
  };

  const boxClass = () => {
    let isInvalid =
      !player.defaultNumber ||
      player.valid === false ||
      player.validNumber === false;
    if (isInvalid) return classes.boxInvalidPlayer;
    if (!isInvalid) return classes.boxPlayer;
  };

  return (
    <Box border={1} className={boxClass()}>
      <Grid
        container
        direction="column"
        justify="flex-end"
        alignItems="center"
        alignContent="flex-end"
        wrap="nowrap"
      >
        {/* Caso seja capitão */}

        <Grid
          className={classes.badgeBox}
          container
          direction="column"
          alignItems="center"
          justify="space-between"
          wrap="nowrap"
        >
          {player.isCaptain && (
            <Grid item xs={12}>
              <Box borderRadius={2} className={classes.capitain}>
                <Typography variant="body2" className={classes.title}>
                  C
                </Typography>
              </Box>
            </Grid>
          )}

          {isGolKeeper(player) && (
            <Grid item xs={12}>
              <Box borderRadius={2} className={classes.golkeeper}>
                <Typography variant="body2" className={classes.title}>
                  G
                </Typography>
              </Box>
            </Grid>
          )}
        </Grid>

        <Grid item className={classes.inputClass}>
          <InputBase
            disabled={mainContext.isMatchDone}
            className={classes.number}
            value={player.defaultNumber ?? ''}
            placeholder="?"
            inputProps={{
              maxLength: 2,
              style: { textAlign: 'center' },
            }}
            onChange={(e) => {
              playersHook.changeNumber(e.target.value, player);
            }}
          />
        </Grid>
        <Grid item className={classes.nicknameBox}>
          <div
            onDoubleClick={() => {
              console.table(player);
              playersHook.setCaptain(player);
            }}
          >
            <Tooltip title={player.nickName}>
              <Typography variant="h4" className={classes.nickname}>
                {functions.setNicknameWith(player, 8)}
              </Typography>
            </Tooltip>
          </div>
        </Grid>
        <Grid item>
          <Divider className={classes.divider} />
        </Grid>
        <Grid item className={classes.position}>
          <Typography noWrap variant="body2">
            {player.position.name}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}
