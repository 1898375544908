import React, { useState, createContext, useMemo, useCallback } from 'react';
import repository from '../../data/repository';
import constants from '../../utils/constants';
import { useHistory } from 'react-router';
import PlayerServices from '../../services/player.services';
import MatchServices from '../../services/match.services';
import { firstBy } from 'thenby';
import functions from '../../utils/functions';

export const MatchContext = createContext();

export default function MatchProvider(props) {
  const history = useHistory();

  const data = useMemo(() => repository(), []);
  const initialStep = () =>
    data.getItem(constants.SCALATIONSTEP) === null
      ? 0
      : parseInt(data.getItem(constants.SCALATIONSTEP));
  const initialIndex = () =>
    data.getItem(constants.INDEX) === null
      ? 0
      : parseInt(data.getItem(constants.INDEX));
  const initialUser = () => data.getItemParse(constants.USER);
  const initialMatch = () => data.getItemParse(constants.MATCH);

  const playerServices = PlayerServices();
  const matchServices = MatchServices();

  // ---------------------------------------------------------------------
  // HOOKS
  // ---------------------------------------------------------------------
  const [match, setMatch] = useState(initialMatch);
  const [user, setUser] = useState(initialUser);
  const [step, setStep] = useState(initialStep);
  const [index, setIndex] = useState(initialIndex);
  const [updatedPlayers, setUpdatedPlayers] = useState([]);
  const [updatedReferees, setUpdateReferess] = useState([]);
  const [updatedCronometer, setUpdatedCronometer] = useState(undefined);
  const [isLoading, setLoading] = useState(false);
  const [order, setOrder] = useState('');
  const [orderBy, setOrderBy] = useState('');
  const [isMatchDone, setIsMatchDone] = useState(
    match && match.status ? match.status.name === 'Aprovada' : false
  );

  // ---------------------------------------------------------------------
  // ERRORS
  // ---------------------------------------------------------------------

  const [error, setError] = useState(false);
  const [scalationError, setScalationError] = useState(false);
  const [goalKeeperError, setGoalKeeperError] = useState(false);
  const [playerNumberError, setPlayerNumberError] = useState(false);
  const [hasNoNumberError, setHasNoNumberError] = useState(false);
  const [hasNoCaptainError, setHasNoCaptainError] = useState(false);
  const [hasNoGoalKeeper, setHasNoGoalKeeperError] = useState(false);
  const [hasNoCronometerError, setHasNoCronometerError] = useState(false);

  // ---------------------------------------------------------------------
  // METHODS
  // ---------------------------------------------------------------------

  const updateMatch = useCallback(() => {
    setLoading(true);
    setMatch({ ...match });
    data.setItemStringify(constants.MATCH, match);
    setLoading(false);
  }, [match, data]);

  const resetTeamPlayersOrder = async (players) => {
    for await (let player of players) {
      player.filter.position = player.position;
      player.filter.defaultNumber = player.defaultNumber;
      player.filter.isStarter = player.isStarter;
      player.filter.isReserve = player.isReserve;
      player.filter.isCaptain = player.isCaptain;
    }
  };

  const resetSort = () => {
    setOrder('');
    setOrderBy('');
  };

  const configEscalation = (team) => {
    team.players.forEach((player) => {
      player.isStarterOnTime = player.isStarter;
      player.isReserveOnTime = player.isReserve;
    });
  };

  const sortPlayers = (team) => {
    team.players.sort(
      firstBy(function (a, b) {
        let right =
          b.filter['isStarter'] === undefined
            ? b['isStarter']
            : b.filter['isStarter'];
        let left =
          a.filter['isStarter'] === undefined
            ? a['isStarter']
            : a.filter['isStarter'];

        return right - left;
      })
        .thenBy(function (a, b) {
          let right =
            b.filter['isReserve'] === undefined
              ? b['isReserve']
              : b.filter['isReserve'];
          let left =
            a.filter['isReserve'] === undefined
              ? a['isReserve']
              : a.filter['isReserve'];

          return right - left;
        })
        .thenBy(function (a, b) {
          let right =
            b.filter['defaultNumber'] === undefined
              ? b['defaultNumber'] === undefined
                ? 999
                : parseInt(b['defaultNumber'])
              : parseInt(b.filter['defaultNumber']);
          let left =
            a.filter['defaultNumber'] === undefined
              ? a['defaultNumber'] === undefined
                ? 999
                : parseInt(a['defaultNumber'])
              : parseInt(a.filter['defaultNumber']);

          return left - right;
        })
      // .thenBy("nickName")
      // .thenBy("name")
    );
  };

  const updateUser = () => {
    setUser({ ...user });
    data.setItemStringify(constants.USER, user);
  };

  const load = (response) => {
    seDefaultClockComment(response.match);
    sortPlayers(response.match.home);
    sortPlayers(response.match.away);
    configEscalation(response.match.home);
    configEscalation(response.match.away);
    setMatch(response.match);
    setUser(response.user);
    setIndex(0);
    setStep(0);
    setIsMatchDone(
      response.match.status ? response.match.status.name === 'Aprovada' : false
    );
    data.setItem(constants.SCALATIONSTEP, 0);
    data.setItemStringify(constants.MATCH, response.match);
    data.setItemStringify(constants.USER, response.user);
    data.setItem(constants.TIMER, response.match.clockComment.startTime);

    setLoading(false);
  };

  const seDefaultClockComment = (match) => {
    let defaultClockComment = functions.getDefaultClockComment();
    match.clockComment = !match.clockComment
      ? defaultClockComment
      : match.clockComment;
    match.clockComment.clockType = !match.clockComment.clockType
      ? defaultClockComment.clockType
      : match.clockComment.clockType;
  };

  const updatePlayer = (player) => {
    const p = updatedPlayers.find((item) => item.id === player.id);
    if (p) {
      setUpdatedPlayers((prev) =>
        updatedPlayers.map((item) => (item.id === p.id ? p : item))
      );
    } else {
      setUpdatedPlayers([...updatedPlayers, player]);
    }
  };

  const addReferee = (referee) => {
    match.referees.push(referee);
  };

  const updateReferee = (referee) => {
    const rf = match.referees.find(
      (item) => item.refereeFunction.id === referee.refereeFunction.id
    );
    if (!rf) {
      addReferee(referee);
    } else {
      match.referees = match.referees.map((item) =>
        item.refereeFunction.id === rf.refereeFunction.id ? rf : item
      );
    }

    const r = updatedReferees.find(
      (item) => item.refereeFunction.id === referee.refereeFunction.id
    );
    if (r) {
      setUpdateReferess(
        updatedReferees.map((item) =>
          item.refereeFunction.id === r.refereeFunction.id ? r : item
        )
      );
    } else {
      setUpdateReferess([...updatedReferees, referee]);
    }
    updateMatch();
  };

  const getTeamByStep = () => {
    var team;

    switch (step) {
      case 0:
        team = match.home;
        break;

      case 1:
        team = match.away;
        break;

      default:
        break;
    }

    return team;
  };

  const updateTeam = async () => {
    if (updatedPlayers.length > 0) {
      let team = getTeamByStep();
      let resp = await playerServices.updatePlayer(match, team, updatedPlayers);
      setLoading(false);
      if (resp.status === 200) {
        resetSort();
        await resetTeamPlayersOrder(
          step === 0 ? match.home.players : match.away.players
        );
        sortPlayers(step === 0 ? match.home : match.away);
        setUpdatedPlayers([]);
        updateMatch();
        return true;
      } else {
        setError(true);
        return false;
      }
    }
  };

  const updateRefereeAndClock = (clockComment) => {
    match.clockComment = clockComment;
    setUpdatedCronometer(clockComment);
    updateMatch();
  };

  const updateRefereesAndCronometer = async () => {
    if (updatedReferees.length > 0 || updatedCronometer !== undefined) {
      let resp = await matchServices.updateReferees(match);
      setLoading(false);
      if (resp.status === 200) {
        setUpdateReferess([]);
        setUpdatedCronometer(undefined);
        return true;
      } else {
        setError(true);
        return false;
      }
    }
  };

  const hasErrors = (players) => {
    if (players.filter((x) => x.valid === false).length > 0) {
      setGoalKeeperError(true);
      return true;
    } else if (players.filter((x) => x.validNumber === false).length > 0) {
      setPlayerNumberError(true);
      return true;
    } else if (
      players.filter(
        (x) =>
          (x.isStarter === true || x.isReserve === true) && !x.defaultNumber
      ).length > 0
    ) {
      setHasNoNumberError(true);
      return true;
    } else if (players.filter((x) => x.isCaptain === true).length === 0) {
      setHasNoCaptainError(true);
      return true;
    } else if (
      players.filter((x) => x.isStarter === true && x.position.id === 1)
        .length === 0
    ) {
      setHasNoGoalKeeperError(true);
      return true;
    }

    return false;
  };

  const hasErrorsOnIndex = (players) => {
    if (players.filter((x) => x.valid === false).length > 0) {
      return true;
    } else if (players.filter((x) => x.validNumber === false).length > 0) {
      return true;
    } else if (
      players.filter(
        (x) =>
          (x.isStarter === true || x.isReserve === true) && !x.defaultNumber
      ).length > 0
    ) {
      return true;
    } else if (players.filter((x) => x.isCaptain === true).length === 0) {
      return true;
    } else if (
      players.filter((x) => x.isStarter === true && x.position.id === 1)
        .length === 0
    ) {
      return true;
    }

    return false;
  };

  const hasErrorsRefereesAndCronometer = () => {
    if (
      match.clockComment.clockType.id === 2 &&
      match.clockComment.startTime === 0
    ) {
      setHasNoCronometerError(true);
      return true;
    }
    return false;
  };

  const updateStep = async (value) => {
    setLoading(true);
    // ------------------------------------------------------------------
    // Atualiza o Time
    // ------------------------------------------------------------------
    if (step !== 2) {
      let team = getTeamByStep();
      if (!hasErrors(team.players)) {
        await updateTeam();
      } else {
        setLoading(false);
        return false;
      }
    }

    // ------------------------------------------------------------------
    // Atualiza o Juízes e Cronômetro
    // ------------------------------------------------------------------
    if (step === 2) {
      if (!hasErrorsRefereesAndCronometer()) {
        await updateRefereesAndCronometer();
      } else {
        setLoading(false);
        return false;
      }
    }

    setStep(value);
    data.setItem(constants.SCALATIONSTEP, value);
    setLoading(false);
    return true;
  };

  const selectIndex = async (event, s) => {
    if (updatedPlayers.length > 0) {
      let status = await updateStep(step);
      if (!status) return;
    }

    if (s === null) return;
    let index = parseInt(s);

    let hasError = updateIndex(index);
    setScalationError((prev) => hasError);

    if (!hasError || index === 0) {
      setIndex((prev) => index);
      data.setItem(constants.INDEX, index);
    }
    return;
  };

  const updateIndex = (value) => {
    // ------------------------------------------------------------------
    // Atualiza o Time
    // ------------------------------------------------------------------
    var error = true;
    if (step !== 2) {
      let team = match.home;
      error = hasErrorsOnIndex(team.players);
      if (error) return error;

      team = match.away;
      error = hasErrorsOnIndex(team.players);
      if (error) return error;
    }

    // ------------------------------------------------------------------
    // Atualiza o Juízes e Cronômetro
    // ------------------------------------------------------------------
    if (step === 2) {
      error = hasErrorsRefereesAndCronometer();
      if (error) return error;
    }
    return error;
  };

  const logout = () => {
    data.clear();
    setLoading((prev) => true);
    setMatch((prev) => {});
    setUser((prev) => {});
    setUpdatedPlayers((prev) => []);
    setUpdateReferess((prev) => []);
    setUpdatedCronometer((prev) => undefined);
    setLoading((prev) => false);
    setError((prev) => false);
    setIsMatchDone((prev) => false);
    data.clear();
    history.push('/');
    window.location.reload();
  };

  return (
    <MatchContext.Provider
      value={{
        match,
        setMatch,
        user,
        updateMatch,
        updateUser,
        updatePlayer,
        addReferee,
        updateReferee,
        updatedReferees,
        updatedPlayers,
        setUpdatedPlayers,
        updateRefereeAndClock,

        updatedCronometer,
        setUpdatedCronometer,
        sortPlayers,
        resetTeamPlayersOrder,
        order,
        orderBy,
        setOrder,
        setOrderBy,

        step,
        index,
        selectIndex,
        updateStep,
        load,
        logout,
        isLoading,

        // ERRORS
        setError,
        error,
        setGoalKeeperError,
        goalKeeperError,
        setPlayerNumberError,
        playerNumberError,
        setHasNoNumberError,
        hasNoNumberError,
        setHasNoCaptainError,
        hasNoCaptainError,
        hasNoGoalKeeper,
        setHasNoGoalKeeperError,
        hasNoCronometerError,
        setHasNoCronometerError,
        scalationError,
        setScalationError,
        isMatchDone,
        setIsMatchDone,
      }}
    >
      {props.children}
    </MatchContext.Provider>
  );
}
