import React, { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";

// My Components
import FButton from "../button/FButton";

function Alert({ title, message, handleOk }) {
    const [open, setOpen] = useState(message != null && message.length > 0);

    const handleClose = () => {
        setOpen(false);
        if (handleOk) handleOk();
    };

    useEffect(() => {
        if (message != null && message.length > 0) setOpen(true);
    }, [message]);

    return (
        <div>
            <Dialog open={open} disableBackdropClick={true} onClose={handleClose} maxWidth="sm" fullWidth={true}>
                <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {message}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <FButton thin onClick={handleClose} type="main">
                        OK
          </FButton>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default Alert;
