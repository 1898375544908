import { api } from "../services/api.services";

export default function MatchServices() {
  const updateReferees = async (match) => {
    let objReferees = {
      matchId: match.id,
      referees: match.referees,
      clockComment: {
        startTime: match.clockComment.startTime,
        clockType: {
          id: match.clockComment.clockType.id,
          name: match.clockComment.clockType.name,
        },
      },
    };

    try {
      var resp = await api.put("match/updatereferees", objReferees);
      return resp;
    } catch (error) {
      return error;
    }
  };

  const updateCronometer = async (team) => {
    try {
      var resp = await api.post("team/addplayertoteam", team);
      return resp.data;
    } catch (error) {
      return error;
    }
  };

  return {
    updateReferees: updateReferees,
    updateCronometer: updateCronometer,
  };
}
