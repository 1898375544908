import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { useHistory } from "react-router";
import { makeStyles } from "@material-ui/core/styles";

// My custom components
import Validators from "../../../../utils/validators";
import FTextInput from "../../../../components/inputs/FTextInput";
import FController from "../../../../components/inputs/FControllerHook";
import FButton from "../../../../components/button/FButton";
import Alert from "../../../../components/pops/Alert";
import Loading from "../../../../components/loading/loading";
import useChangePassword from "../hooks/changePassword.hook";
import AuthLogo from "../../../../components/logos/auth.logo";
import Confirm from "../../../../components/pops/Confirm";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    width: 500,
    height: 840,
    borderRadius: 10,
  },
  logo: {
    paddingBottom: theme.spacing(12),
  },
  grid: {
    maxWidth: 350,
  },
  divider: {
    background: theme.palette.divider,
  },
  itemCenter: {
    alignSelf: "center",
  },
  spacer: {
    paddingBottom: theme.spacing(2),
  },
}));

function NewPassword({ user }) {
  const classes = useStyles();
  const [credentials, setCredentials] = useState({});
  const [loading, response] = useChangePassword(credentials);
  const [showPop, setShowPop] = useState(false);
  const [showCancel, setShowCancel] = useState(false);
  // InputControllers

  const password = FController();
  const confirmPassword = FController();
  const valitators = Validators();
  const history = useHistory();

  useEffect(() => {
    if (response && response.data) setShowPop(true);
  }, [response]);

  const handleUpdatePassword = async (e) => {
    e.preventDefault();
    let hasError = await validate();
    if (!!hasError) {
      setCredentials({
        id: user.id_Login,
        password: password.value,
        confirmPassword: confirmPassword.value,
        valid: !!hasError,
      });
    }
  };

  const validate = async () => {
    if (!password.value) {
      password.setError("Por favor informe a Senha");
      return false;
    }

    if (!confirmPassword.value) {
      confirmPassword.setError("Por favor confirme a Senha");
      return false;
    }

    if (password.value.toLowerCase() !== confirmPassword.value.toLowerCase()) {
      confirmPassword.setError("As senhas não conferem");
      return false;
    }

    return true;
  };

  const handleOk = () => {
    setShowPop(false);
    if (response && response.status === 200) {
      history.push("/");
    }
  };

  const handleShowCancel = () => {
    setShowCancel(true);
  };

  const handleCancelOk = () => {
    setShowCancel(false);
    history.push("/");
  };

  const handleCancelAbort = () => {
    setShowCancel(false);
  };

  return (
    <div className={classes.root}>
      {showPop && (
        <Alert
          title="Recuperar Senha"
          handleOk={handleOk}
          message={response.data.message}
        />
      )}

      {showCancel && (
        <Confirm
          title="Recuperar Senha"
          handleOk={handleCancelOk}
          handleAbort={handleCancelAbort}
          message="Deseja realmente cancelar? Você pode realizar essa operação mais tarde tranquilamente."
        />
      )}
      <Loading isLoading={loading} />

      <Grid
        className={classes.grid}
        container
        direction="column"
        alignItems="stretch"
        justify="center"
      >
        <Grid item className={classes.logo}>
          <AuthLogo />
        </Grid>
        <Grid item className={classes.spacer}>
          <FTextInput
            type="password"
            label="Nova senha"
            controller={password}
            disabled={loading}
            validations={[valitators.isRequiredPassword]}
          ></FTextInput>
        </Grid>
        <Grid item className={classes.spacer}>
          <FTextInput
            type="password"
            label="Confirme a nova senha"
            controller={confirmPassword}
            disabled={loading}
            validations={[valitators.isRequiredPassword]}
          ></FTextInput>
        </Grid>
        <Grid item className={classes.spacer}>
          <br></br>
          <Grid container pt={10} spacing={2}>
            <Grid item xs={6}>
              <FButton
                fullWidth
                disabled={loading}
                type="cancel"
                onClick={handleShowCancel}
              >
                Cancelar
              </FButton>
            </Grid>
            <Grid item xs={6}>
              <FButton
                fullWidth
                disabled={loading}
                onClick={handleUpdatePassword}
              >
                Confirmar
              </FButton>
            </Grid>
          </Grid>
        </Grid>

        <Loading />
      </Grid>
    </div>
  );
}

export default NewPassword;
