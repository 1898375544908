import React, { useCallback, useEffect, useRef } from 'react'
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { firstBy } from "thenby";

// My components
import ScoreBoardTeam from "../../components/bigHeader/scoreBoardTeam";
import FCard from "../../../../../theme/animations/fcard.animations";
import Shake from "../../../../../theme/animations/shake.animations";
import PlayerPopCard from "../../components/cards/player.pop.card";
import TechnicalPopCard from "../../components/cards/technical.pop.card";
import useOccurrenceHook from "../hooks/occorrence.hook";

const useStyles = makeStyles((theme) => ({
    teamBox: {
        maxWidth: '49%',
        flexBasis: '49%',
        textAlign: 'center'
    },
    inactive: {
        "& > *": { color: "lightgrey !important" },
        "& > td > *": { color: "lightgrey !important" },
    },
}));

export default function PopTeamBody({
    team,
    title,
    setPlayer,
    showStarterTitle = true,
    showTeamName = true,
    showStarters = true,
    showReserves = false,
    showTechnical = false,
    justify = 'center'
}) {

    // Styles
    const classes = useStyles();

    const occurrenceHook = useOccurrenceHook();
    const players = team.players;
    const technical = [...team.technicalMembers];

    const adjustPositions =(items) =>{
        const players2 = [];
        var p = items;
        var goleiro = p.find(element => element.position.name === "Goleiro");
        var outros = p.filter(e => e.position.name != 'Goleiro');
        var goleiros = p.filter(element => element.position.name === "Goleiro");

        goleiros.forEach(function(g){
            players2.push(g);
          });

       // console.log("goleiros", goleiros);
       
        var final =  players2.concat(outros);

        //console.log("who.players.final", final)
        return final;
      };

    const filterOccurrences = useCallback((player) => {
        var currentOccurrences = occurrenceHook.currentOccurrences.filter(function (occurrence) {
            return occurrence.player
                && occurrence.player.id === player.id
                && occurrence.team.id === team.id
                && [21, 22].includes(occurrence.occurrenceType.id);
        });

        // if (currentOccurrences.length > 0) return currentOccurrences;
        return currentOccurrences;

        // var occurrences = occurrenceHook.occurrences.filter(function (occurrence) {
        //     return occurrence.player
        //         && occurrence.player.id === player.id
        //         && occurrence.team.id === team.id
        //         && occurrenceHook.activeOccurrenceTime
        //         && (
        //             (occurrence.gameTime.id === occurrenceHook.activeOccurrenceTime.gameTime.id
        //                 && occurrence.timeType.id <= occurrenceHook.activeOccurrenceTime.timeType.id)
        //             ||
        //             (occurrence.gameTime.id <= occurrenceHook.activeOccurrenceTime.gameTime.id
        //                 && occurrence.timeType.id === occurrenceHook.activeOccurrenceTime.timeType.id)
        //         )
        //         && [21, 22].includes(occurrence.occurrenceType.id);
        // });

        // return occurrences;
    }, [
        // occurrenceHook.activeOccurrenceTime,
        // occurrenceHook.occurrences,
        occurrenceHook.currentOccurrences,
        team.id])

    const configureScalation = useCallback((player) => {

        var items = [];
        items = filterOccurrences(player);
        if (items.length === 0) {
            player.isReserveOnTime = player.isReserve;
            player.isStarterOnTime = player.isStarter;
            return;
        }

        items.sort(firstBy(function (v1, v2) { return v1.id - v2.id; }));

        if (items.length >= 1 && items[items.length - 1].occurrenceType.id === 21) {
            player.isReserveOnTime = true;
            player.isStarterOnTime = false;
        }
        if (items.length >= 1 && items[items.length - 1].occurrenceType.id === 22) {
            player.isReserveOnTime = false;
            player.isStarterOnTime = true;
        }

     

    }, [filterOccurrences])


    const initConfituration = useCallback(() => {
        players.forEach(player => {

            // Caso o player seja titular ou reserva, ai sim eu chamo a configuração
            if (player.isStarter === true || player.isReserve === true) {
                configureScalation(player);
            }
            else {
                player.isReserveOnTime = undefined;
                player.isStarterOnTime = undefined;
            }

        });
     
    }, [players, configureScalation])

    useEffect(() => {
        team.players  =  adjustPositions(team.players);
        initConfituration()
       
    }, [initConfituration])

    return (
        <Grid item container >
            <Grid container item direction="column" spacing={2}>

                {/* Titulares */}
                {showTeamName &&
                    <Grid item>
                        <ScoreBoardTeam showShield={false} showFaults={false} title={title} team={team} ></ScoreBoardTeam>
                    </Grid>
                }

                {showStarterTitle && showStarters &&
                    <Grid item container justify={justify} spacing={0} >
                        <Typography variant="h4" >
                            Titulares
                        </Typography>
                    </Grid>
                }
                {/* Titulares  */}

                {showStarters &&
                    <Grid item container justify={justify} spacing={1} className={classes.players}>
                        {players.map((player) => (

                            <FCard key={player.id} values={player.isStarterOnTime}>
                                <Shake values={player.enableToSubstituition === 1}>
                                    <PlayerPopCard
                                        occurrenceHook={occurrenceHook}
                                        team={team}
                                        player={player}
                                        setSelected={setPlayer}
                                    ></PlayerPopCard>
                                </Shake>
                            </FCard>

                        ))}
                    </Grid>
                }

                {/* Reservas  */}
                {showReserves &&
                    <>
                        <Grid item container justify={justify} spacing={0}>
                            <Typography variant="h4" >
                                Reservas
                            </Typography>
                        </Grid>
                        <Grid item container justify={justify} spacing={1} className={classes.players}>
                            {players.map((player) => (
                                <FCard key={player.id} values={player.isReserveOnTime}>
                                    <Shake values={player.enableToSubstituition === 1}>
                                        <PlayerPopCard
                                            occurrenceHook={occurrenceHook}
                                            team={team}
                                            player={player}
                                            setSelected={setPlayer}
                                        ></PlayerPopCard>
                                    </Shake>
                                </FCard>
                            ))}
                        </Grid>
                    </>
                }

                {/* Equipe Técnica  */}
                {showTechnical &&
                    <>
                        <Grid item container justify={justify} spacing={0}>
                            <Typography variant="h4" className={classes.title} >
                                Comissão Técnica
                            </Typography>
                        </Grid>
                        <Grid item container justify={justify} spacing={1} className={classes.players}>
                            {technical.map((player) => (
                                <FCard key={player.id} values={player.isStarter}>
                                    <TechnicalPopCard
                                        occurrenceHook={occurrenceHook}
                                        team={team}
                                        player={player}
                                        setSelected={setPlayer}
                                    ></TechnicalPopCard>
                                </FCard>
                            ))}
                        </Grid>
                    </>
                }
            </Grid>
        </Grid >
    )


}
