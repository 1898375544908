import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";
import Backdrop from "@material-ui/core/Backdrop";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  progress: {
    color: "white",
    zIndex: theme.zIndex.drawer + 1,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -17,
  },
  wait: {
    color: "white",
    paddingTop: 100,
    paddingLeft: 15,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export default function Loading(props) {
  const classes = useStyles();
  const { isLoading, value } = props;

  const loading = () => {
    return isLoading === true || value === true;
  };
  return (
    <Box>
      <Backdrop className={classes.backdrop} open={loading()}>
        <CircularProgress className={classes.progress}></CircularProgress>
        <br></br>
        <Typography className={classes.wait} variant="body1">
          Aguarde...
        </Typography>
      </Backdrop>
    </Box>
  );
}
